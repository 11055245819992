import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Button from "@mui/material/Button";
import cover from "../images/logo.png";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import { useUser } from "../components/UserContext";
import { IconButton } from "@mui/material";
import Back from '@mui/icons-material/ArrowCircleLeft'

const PrintReceipt = () => {
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const { user: userDetails } = useUser();
  const { transactionId } = useParams();
  const history = useHistory();

  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [transactionDetails, setTransactionDetails] = useState(null);
  const printArea = useRef();

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/printReceipt/${transactionId}`
        );
        setTransactionDetails(response.data);
      } catch (error) {
        console.error("Error fetching transaction details:", error);
      }
    };
    fetchTransactionDetails();
  }, [apiUrl, transactionId]);

  // Handle receipt printing to PDF
  const handlePrintReceipt = () => {
    const content = printArea.current;
    if (content) {
      html2canvas(content, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("receipt.pdf");
        openSnackbar("Receipt downloaded as PDF!");
      });
    } else {
      openSnackbar("An Error Occurred!");
    }
  };

  const openSnackbar = (message) => {
    setSnackbarKey((prevKey) => prevKey + 1);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const action = (
    <Button
      sx={{ color: "#fff" }}
      size="small"
      onClick={() => setSnackbarOpen(false)}
    >
      <i className="bi bi-x-lg"></i>
    </Button>
  );

  return (
    <div>
      <br /><br /><br /><br /><br />

      <div className="receipt" >
      <div className='pb-3'> 
      <IconButton className="bg-light" onClick={() => history.goBack()}><Back/></IconButton>
      </div>

        <div ref={printArea}>
        <div className="receipt-header">
          <div className="d-flex justify-content-between">
            <h6 className="mt-3">Transaction <br /> Receipt</h6>
            <img className="mt-auto" src={cover} alt="" width={80} />
          </div>
        </div>

        <div className="body">
          <div className="mt-3 bg-light p-3 rounded text-center text-capitalize">
            <p className="">{transactionDetails?.type} transaction</p>
            <h5 style={{ fontSize: '30px', fontWeight: 900 }}>${transactionDetails?.amount}.00 </h5>
            <p style={{ marginTop: '-10px', fontSize: '10px' }}>
              {moment(transactionDetails?.date).format("DD MMM YYYY")} -
              {moment(transactionDetails?.time, "HH:mm:ss").format("h:mmA")}
            </p>
          </div>

          <div className="mt-3 bg-light p-3 top d-flex justify-content-between">
            <small>Transaction Date & Time</small>
            <small>
              {moment(transactionDetails?.date).format("DD MMM YYYY")} -
              {moment(transactionDetails?.time, "HH:mm:ss").format("h:mmA")}
            </small>
          </div>

          <div className="mt-1 p-3 d-flex justify-content-between center" style={{ background: '#fbfbfb' }}>
            <small>Routing | Swift Code</small>
            <small>{transactionDetails?.routing} | {userDetails?.swiftCode}</small>
          </div>

          <div className="mt-1 p-3 d-flex justify-content-between center bg-light">
            <small>Description</small>
            <small>{transactionDetails?.description}</small>
          </div>

          <div className="mt-1 p-3 d-flex justify-content-between last" style={{ background: '#fbfbfb' }}>
            <small>Transaction Reference</small>
            <small>{Math.floor(1000000 + Math.random() * 90000000000)}</small>
          </div>

          <div className="text-center mt-3 m-auto" style={{ width: '200px' }}>
            <p>For complaints regarding this transaction, contact our support team.</p>
          </div>
        </div>
        </div>
        
      </div>

      <div className="text-center my-4">
        <Button
          variant="contained"
          color="primary"
          onClick={handlePrintReceipt}
        >
          Download Receipt as PDF
        </Button>
      </div>

      <Snackbar
        key={snackbarKey}
        open={snackbarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        action={action}
        onClose={() => setSnackbarOpen(false)}
      />
    </div>
  );
};

export default PrintReceipt;
