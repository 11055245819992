/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";

import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CurrencyBitcoin from "@mui/icons-material/Paid";
import Support from "@mui/icons-material/SupportAgent";
import AccountBalance from "@mui/icons-material/ContactlessSharp";
import Savings from "@mui/icons-material/Savings";
import InterBankIcon from "@mui/icons-material/SwapHorizontalCircle";
import DomesticIcon from "@mui/icons-material/SwapVerticalCircle";

import Intrabank from "./Actions/Intrabank";
import Wire from "./Actions/Wire";
import Domestic from "./Actions/Domestic";

import { CloseButton, Modal } from "react-bootstrap";
import RecentTransactionsTable from "./Actions/RecentTransactions";
import { useUser } from "../components/UserContext";

const Dashboard = () => {
  const location = useLocation();
  const client = location.state && location.state.client;
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;
  const [depositeWithBtc, setDepositeWithBtc] = useState(false);

  const [openWireTransfer, setOpenWireTransfer] = useState(false);
  const [openDomesticTransfer, setOpenDomesticTransfer] = useState(false);
  const [openIntraBankTransfer, setOpenIntraBankTransfer] = useState(false);
  const [userIsBlocked, setUserIsBlocked] = useState(false);
  const [depositeAccount, setDepositeAccount] = useState("");
  const [depositeName, setDepositeName] = useState("");
  const [depositeAmount, setDepositeAmount] = useState("");
  const [showDepositeWarning, setShowDepositeWarning] = useState(false);
  const [ip, setIp] = useState(null);
  const history = useHistory();
  const [copiedText, setCopiedText] = useState(null);
  const [transferMenuAnchor, setTransferMenuAnchor] = useState(null);
  const { user: userDetails, loadingBalance } = useUser();
  
  const firstName =
    (client && client.firstName) || (userDetails && userDetails.firstName);
  const profilePicture =
    (client && client.imageFileName) ||
    (userDetails && userDetails.imageFileName);
    // const queryParams = new URLSearchParams(location.search);
    // const id = queryParams.get("id");

    // const balance =
    // (client && client.balance) || (userDetails && userDetails.balance);
    const [balance, setBalance] = useState(null)

  const handleOpenWireTransfer = () => {
    setOpenWireTransfer(true);
    setTransferMenuAnchor(null);
  };
  const handleOpenDomesticTransfer = () => {
    setOpenDomesticTransfer(true);
    setTransferMenuAnchor(null);
  };
  const handleOpenIntraBankTransfer = () => {
    setOpenIntraBankTransfer(true);
    setTransferMenuAnchor(null);
  };

  const handleCloseWireTransfer = () => {
    setOpenWireTransfer(false);
  };

  const handleCloseDomesticTransfer = () => {
    setOpenDomesticTransfer(false);
  };
  const handleCloseIntraBankTransfer = () => {
    setOpenIntraBankTransfer(false);
  };

  const [deposite, setDeposite] = useState(false);
  const handleDepositeMenuClick = () => {
    setDeposite(true);
  };

  const handleTransferMenuClick = (event) => {
    setTransferMenuAnchor(event.currentTarget);
    axios
      .get(`${apiUrl}/clients/${userDetails.account}`)
      .then((response) => {
        const userStatus = response.data[0]?.status;
        if (userStatus === "blocked") {
          setTransferMenuAnchor(null);
          setUserIsBlocked(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching user status:", error);
      });
  };

  const handleTransferMenuClose = () => {
    setTransferMenuAnchor(null);
  };

  //fetch ip address
  useEffect(() => {
    // if (userDetails) {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        const ipAddress = response.data.ip;
        setIp(ipAddress);
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
    // }
  }, []);


    //get balance
    useEffect(() => {
      const effectiveId = client?.id || userDetails?.id;
      if (effectiveId) {
        axios
          .get(`${apiUrl}/clients/${effectiveId}`)
          .then((response) => {
            const clientData = response.data?.[0]?.balance || 0;
            setBalance(clientData);
          })
          .catch((error) => {
            console.error("Error fetching client data:", error);
          });
      }
    }, [apiUrl, client, userDetails]);
    
    

  const processDeposit = () => {
    if (depositeAccount !== "" && depositeName !== "") {
      setShowDepositeWarning(true);
      setDepositeName("");
      setDepositeAccount("");
      setDepositeAmount("");
    } else {
      return;
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedText(text);
        setTimeout(() => setCopiedText(null), 2000); // Remove "Copied!" after 2 seconds
      })
      .catch((error) => console.error("Copy to clipboard failed: ", error));
  };

  useEffect(() => {
    if (!client && userDetails?.role === "admin") {
      history.push("/admin");
    } else if (!client && userDetails?.role === "client") {
      history.push("/dashboard");
    } else if (!client && !userDetails) {
      history.push("/login");
    }
  }, []);

  return (
    <div className={`${userDetails?.role === "admin" && "mt-5"}`}>
      <div className="dashboard">
        <div className="container pb-5">
          {client && (
            <div>
              <h6 className="text-muted" style={{fontSize:12, fontWeight: 900}}>
                You are viewing the admin version of this client dashboard
              </h6>
            </div>
          )}
          <div className="cards p-4 rounded-3 shadow-1 position-relative">
            <div className="pb-2">
              <div className="py-3 d-flex justify-content-between">
                <div className="">
                  <div
                    className="d-flex p-1 pe-5 rounded-5"
                    style={{
                      background: "#193c7c",
                      border: "2px solid #384c70",
                    }}
                  >
                    {profilePicture ? (
                      <Avatar
                        sx={{ border: "3px solid #384c70", cursor: "pointer" }}
                        src={`${apiUrl}/images/${profilePicture}`}
                        onClick={() => history.push("/settings?setModal=true")}
                      ></Avatar>
                    ) : (
                      <Avatar
                        sx={{
                          border: "1px solid #384c70",
                          cursor: "pointer",
                          color: "#fff",
                          background: "#0005",
                        }}
                        onClick={() => history.push("/settings?setModal=true")}
                      />
                    )}

                    <h6 className="m-auto mx-2 text-capitalize">
                      {firstName &&
                        firstName.substring(0, 20) +
                          (firstName.length > 20 ? "..." : "")}{" "}
                    </h6>
                  </div>

                  <div className="balance position-absolute top-50">
                    {loadingBalance ? (
                      <div className="spinner-border spinner-border-sm"></div>
                    ) : (
                      <div className="balance my-2">
                    <h5>Balance</h5>
                    <div className="ms-5 amount">
                      {(balance !== 0 || userDetails?.balance !== 0) ? (
                        <div className="d-flex">
                          <div>$</div>
                          <div>
                            {(balance && typeof balance === "number") || typeof userDetails?.balance === "number"
                              ? (balance || userDetails?.balance).toLocaleString("en-US")
                              : "0"}
                          </div>
                          <div>.00</div>
                        </div>
                      ) : (
                        "$00.00"
                      )}
                    </div>
                  </div>

                    )}
                  </div>
                </div>

                <div className="pb-5">
                  <button
                    onClick={() =>
                      history.push({
                        pathname: `${
                          client ? `/history/${client.id}` : "/history"
                        }`,
                        state: { client: client },
                      })
                    }
                    className="btn text-light text-decoration-none"
                    style={{ fontSize: "12px", marginTop: "10px" }}
                  >
                    history <span className="bi bi-chevron-right"></span>
                  </button>
                </div>
              </div>

              <div className="py-3">
                <div className="d-flex p-3">
                  <div className="transfer-deposite-Card">
                    <div className="me-md-2">
                      <div
                        className="card border-0 shadow-sm text-center p-3"
                        style={{ borderRadius: "5px 0 0 5px" }}
                      >
                        <small>
                          Deposite{" "}
                          <span className="bi bi-arrow-down-square-fill text-success ms-4"></span>
                        </small>

                        <div className="py-2 px-3">
                          <Button
                            variant=""
                            size="large"
                            className="deposite"
                            onClick={handleDepositeMenuClick}
                          >
                            deposite
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div
                        className="card border-0 text-center shadow-sm p-3"
                        style={{ borderRadius: "0 5px 5px 0" }}
                      >
                        <small>
                          Transfer{" "}
                          <span className="bi bi-arrow-down-square-fill text-danger ms-4"></span>
                        </small>

                        <div className="py-2 px-3">
                          <Button
                            variant=""
                            size="small"
                            className="transfer"
                            onClick={handleTransferMenuClick}
                          >
                            transfer
                          </Button>
                          <Menu
                            anchorEl={transferMenuAnchor}
                            open={Boolean(transferMenuAnchor)}
                            onClose={handleTransferMenuClose}
                          >
                            <MenuItem onClick={handleOpenWireTransfer}>
                              <ListItemIcon>
                                <AccountBalance fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Wire Transfer</ListItemText>
                            </MenuItem>

                            <MenuItem onClick={handleOpenIntraBankTransfer}>
                              <ListItemIcon>
                                <InterBankIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Intrabank Transfer</ListItemText>
                            </MenuItem>

                            <MenuItem onClick={handleOpenDomesticTransfer}>
                              <ListItemIcon>
                                <DomesticIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Domestic Transfer</ListItemText>
                            </MenuItem>

                            <MenuItem
                              onClick={() => history.push("/WithdrawCrypto")}
                            >
                              <ListItemIcon>
                                <CurrencyBitcoin fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Transfer To bitcoin</ListItemText>
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* LIMIT, IP, LOGIN DATE etc */}
          <div className="mt-5 pt-5 rounded-3 limit-ip-login-date-etc shadow-sm">
            <div className="p-4">
              <div className="d-flex">
                <p>Limit:</p>
                <h6 className="ms-auto fw-bolder">Express</h6>
              </div>

              <div className="d-flex">
                <p>Transfer Limit Remain: </p>
                <h6 className="ms-auto text-danger fw-bolder">No limit</h6>
              </div>

              <div className="d-flex">
                <p>Last Transaction:</p>
                {/* <h6 className="ms-auto fw-bolder">
                  {theLastTransaction && theLastTransaction.length > 0 && theLastTransaction[0] && theLastTransaction[0].amount !== undefined ? (
                    <div key={theLastTransaction[0].id}>
                      ${theLastTransaction[0].amount.toLocaleString("en-US")}.00
                    </div>
                  ) : (
                    <div>No transactions yet</div>
                  )}
                </h6> */}
              </div>
              <div className="d-flex">
                <p>Last Login IP:</p>
                <h6 className="ms-auto fw-bolder text-danger">{ip}</h6>
              </div>

              <div className="d-flex">
                <p>Last Login Date:</p>
                <h6 className="ms-auto fw-bolder">NILL</h6>
              </div>
            </div>
          </div>

          {/*  deposite with bitcoin wallet dialoge  */}
          <div className="withdrawal-dialoge">
            <Dialog open={depositeWithBtc} className="">
              <div className="dialoge">
                <div className="drawer"></div>

                <DialogTitle className="DialogTitle mb-4 shadow-sm">
                  Deposite with crypto currency
                </DialogTitle>

                <DialogContent>
                  <DialogContentText>
                    <small>
                      If you made a payment, come back here and click,{" "}
                      <code>i made a payment</code> and follow the instructions.
                    </small>
                    <div className="text-center my-4">
                      <p className="mb-0">Select our deposite address below</p>
                      <small className="mt-0">click on address to copy</small>
                    </div>
                  </DialogContentText>

                  <div class="text-center">
                    <div class="row g-2 flex-wra">
                      <div class="col-6">
                        <div class="p-3 bg-light">
                          <h6 className="m-0">BITCOIN(BTC)</h6>
                          <div className="overflow-auto">
                            <Button
                              onClick={() =>
                                copyToClipboard("BITCOIN(BTC)5463124566846044")
                              }
                            >
                              <code>54631245668460445463124566846044</code>
                            </Button>
                          </div>
                        </div>
                        {copiedText === "BITCOIN(BTC)5463124566846044" && (
                          <span className="position-absolute top-50 p-2 translate-middle badge bg-secondary">
                            Copied!
                          </span>
                        )}
                      </div>

                      <div class="col-6 position-relative">
                        <div class="p-3 bg-light">
                          <h6 className="m-0">THEREUM(ETH)</h6>
                          <Button
                            onClick={() =>
                              copyToClipboard("THEREUM(ETH)5463124566846044")
                            }
                          >
                            <code>5463124566846044</code>
                          </Button>
                        </div>
                        {copiedText === "THEREUM(ETH)5463124566846044" && (
                          <span className="position-absolute top-50 p-2 translate-middle badge bg-secondary">
                            Copied!
                          </span>
                        )}
                      </div>

                      <div class="col-6 position-relative">
                        <div class="p-3 bg-light">
                          <h6 className="m-0">USDT(TRC-20)</h6>
                          <Button
                            onClick={() =>
                              copyToClipboard("USDT(TRC-20)5463124566846044")
                            }
                          >
                            <code>5463124566846044</code>
                          </Button>
                        </div>
                        {copiedText === "USDT(TRC-20)5463124566846044" && (
                          <span className="position-absolute top-50 p-2 translate-middle badge bg-secondary">
                            Copied!
                          </span>
                        )}
                      </div>

                      <div class="col-6 position-relative">
                        <div class="p-3 bg-light">
                          <h6 className="m-0">USDT(ERC-20)</h6>
                          <Button
                            onClick={() =>
                              copyToClipboard("(USDT-ERC-20)5463124566846044")
                            }
                          >
                            <code>5463124566846044</code>
                          </Button>
                        </div>
                        {copiedText === "(USDT-ERC-20)5463124566846044" && (
                          <span className="position-absolute top-50 p-2 translate-middle badge bg-secondary">
                            Copied!
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <small style={{ fontSize: "13px" }}>
                      <code>IMPORTANT</code>: If you are having issues, please
                      contact our support for payment instructions. Immediately
                      after payment, we'll be notified via your transaction
                      reference code and your account will be credited
                      automatically. Thank you!
                    </small>
                  </div>

                  <div className="pt-3">
                    <Button variant="outlined">i made a payment</Button>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDepositeWithBtc(false)}>
                    Cancel
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          </div>

          {/* chart */}

          <RecentTransactionsTable />
        </div>

        {/* Account restricted warning */}

        <Modal
          show={userIsBlocked}
          onHide={() => setUserIsBlocked(false)}
          backdrop="static"
          centered
        >
          <Modal.Body>
            <div className="p-4 text-center ">
              <i
                className="bi-exclamation-circle text-danger"
                style={{ fontSize: "30px" }}
              ></i>
              <h5>We are sorry</h5>
              <p>
                Your account has been restricted <br /> Contact support
              </p>
              <Button
                className="text-capitalize me-3"
                variant="contained"
                onClick={() => history.push("/contactForm")}
                endIcon={<Support />}
              >
                support
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setUserIsBlocked(false)}
              >
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Account cant deposite warning */}

        <Modal
          show={deposite}
          onHide={() => setDeposite(false)}
          backdrop="static"
          centered
        >
          <Modal.Body>
            <div>
              <div className="position-absolute end-0 mx-3">
                <CloseButton
                  onClick={() => {
                    setDeposite(false);
                    setShowDepositeWarning(false);
                  }}
                />
              </div>

              {showDepositeWarning && (
                <div className="p-4 text-center ">
                  <i
                    className="bi-exclamation-circle text-danger"
                    style={{ fontSize: "30px" }}
                  ></i>
                  <h5>We are sorry</h5>
                  <p>
                    Your account has been restricted to make deposites
                    <br /> Contact support
                  </p>
                  <Button
                    className="text-capitalize me-3"
                    variant="contained"
                    onClick={() => history.push("/contactForm")}
                    endIcon={<Support />}
                  >
                    support
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      setDeposite(false);
                      setShowDepositeWarning(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
              )}

              {!showDepositeWarning && (
                <div className="">
                  <form>
                    <div className="my-3">
                      <h5 className="mb-4">DEPOSIT</h5>
                      <label htmlFor="">Enter Account Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={depositeAccount}
                        onChange={(e) => setDepositeAccount(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="">Enter Receiver Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={depositeName}
                        onChange={(e) => setDepositeName(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="">Enter Amount</label>
                      <input
                        type="text"
                        className="form-control"
                        value={depositeAmount}
                        onChange={(e) => setDepositeAmount(e.target.value)}
                        required
                      />
                    </div>
                    <small>
                      Before Continueing, make sure you read and understand our
                      terms of service
                    </small>
                    <div>
                      <Button
                        type="submit"
                        variant="contained"
                        className="my-4"
                        onClick={processDeposit}
                      >
                        Send <i className="bi-arrow-right-circle ms-3"></i>
                      </Button>
                    </div>
                  </form>

                  {(depositeAccount.length > 0 ||
                    depositeName.length ||
                    depositeAmount.length > 0) && (
                    <div className="border shadow-sm overflow-hidden rounded ">
                      <div className="border-bottom bg-light">
                        <h5 className="p-2">Transaction details</h5>
                      </div>
                      <div className="p-3 text-capitalize text-muted">
                        {depositeAccount.length > 0 && (
                          <h6>Account No.: {depositeAccount} </h6>
                        )}
                        {depositeName.length > 0 && (
                          <h6>Sending to: {depositeName} </h6>
                        )}
                        {depositeAmount.length > 0 && (
                          <h6>Amount: ${depositeAmount} </h6>
                        )}
                        {depositeAmount.length > 0 && (
                          <h6>Transaction Fee: $0 </h6>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* MODALS */}

      {openWireTransfer && (
        <Wire
          openWireModal={openWireTransfer}
          handleCloseWire={handleCloseWireTransfer}
        />
      )}

      {openDomesticTransfer && (
        <Domestic
          openDomesticModal={openDomesticTransfer}
          handleCloseDomestic={handleCloseDomesticTransfer}
        />
      )}

      {openIntraBankTransfer && (
        <Intrabank
          openIntraBankModal={openIntraBankTransfer}
          handleCloseIntraBank={handleCloseIntraBankTransfer}
        />
      )}
    </div>
  );
};

export default Dashboard;
