/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";

const DepositeCrypto = () => {

  const [copiedText, setCopiedText] = useState(null);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedText(text);
        setTimeout(() => setCopiedText(null), 2000); // Remove "Copied!" after 2 seconds
      })
      .catch((error) => console.error("Copy to clipboard failed: ", error));
  };


  return (
    <div className="p-3 pt-0" style={{ maxWidth: "600px", margin: "auto" }}>
      <h4 className="">
        Credit your account with crypto <br />
        currency
      </h4>

      <div className="shadow rounded p-4 mb-5">
        <div>
          <small>
            If you made a payment, come back here and click,{" "}
            <code>i made a payment</code> and follow the instructions.
          </small>
          <div className="text-center my-4">
            <p className="mb-0">Select our deposite address below</p>
            <small className="mt-0">click on address to copy</small>
          </div>
        </div>

        <div class="text-center">
          <div class="row g-2 flex-wra">
            <div class="col-6">
              <div class="p-3 bg-light">
                <h6 className="m-0">BITCOIN(BTC)</h6>
                <div className="overflow-auto">
                  <Button
                    onClick={() =>
                      copyToClipboard("BITCOIN(BTC)5463124566846044")
                    }
                  >
                    <code>54631245668460445463124566846044</code>
                  </Button>
                </div>
              </div>
              {copiedText === "BITCOIN(BTC)5463124566846044" && (
                <span className="position-absolute top-50 p-2 translate-middle badge bg-secondary">
                  Copied!
                </span>
              )}
            </div>

            <div class="col-6 position-relative">
              <div class="p-3 bg-light">
                <h6 className="m-0">THEREUM(ETH)</h6>
                <Button
                  onClick={() =>
                    copyToClipboard("THEREUM(ETH)5463124566846044")
                  }
                >
                  <code>5463124566846044</code>
                </Button>
              </div>
              {copiedText === "THEREUM(ETH)5463124566846044" && (
                <span className="position-absolute top-50 p-2 translate-middle badge bg-secondary">
                  Copied!
                </span>
              )}
            </div>

            <div class="col-6 position-relative">
              <div class="p-3 bg-light">
                <h6 className="m-0">USDT(TRC-20)</h6>
                <Button
                  onClick={() =>
                    copyToClipboard("USDT(TRC-20)5463124566846044")
                  }
                >
                  <code>5463124566846044</code>
                </Button>
              </div>
              {copiedText === "USDT(TRC-20)5463124566846044" && (
                <span className="position-absolute top-50 p-2 translate-middle badge bg-secondary">
                  Copied!
                </span>
              )}
            </div>

            <div class="col-6 position-relative">
              <div class="p-3 bg-light">
                <h6 className="m-0">USDT(ERC-20)</h6>
                <Button
                  onClick={() =>
                    copyToClipboard("(USDT-ERC-20)5463124566846044")
                  }
                >
                  <code>5463124566846044</code>
                </Button>
              </div>
              {copiedText === "(USDT-ERC-20)5463124566846044" && (
                <span className="position-absolute top-50 p-2 translate-middle badge bg-secondary">
                  Copied!
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="mt-2">
          <small style={{ fontSize: "13px" }}>
            <code>IMPORTANT</code>: If you are having issues, please contact our
            support for payment instructions. Immediately after payment, we'll
            be notified via your transaction reference code and your account
            will be credited automatically. Thank you!
          </small>
        </div>

        <div className="py-5">
          <Button variant="outlined">i made a payment</Button>
        </div>
      </div>
    </div>
  );
};

export default DepositeCrypto;
