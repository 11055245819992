import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Drawer,
  Grid,
  Card
} from '@mui/material';
import Snackbar from "@mui/material/Snackbar";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useUser } from "../UserContext";
import verified from '../../images/verified.png'



const WireTransfer = ({ openWireModal, handleCloseWire }) => {
  const steps = ['Initiate Transfer', 'Enter OTP', 'Complete Transfer'];
  const apiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL_PROD;
  const location = useLocation();
  const history = useHistory();
  const client = location.state && location.state.client;
  const { user: userDetails, updateBalance } = useUser();
  const firstName = (client && client.firstName) || (userDetails && userDetails.firstName);
  const lastName = (client && client.lastName) || (userDetails && userDetails.lastName);
  const userAccount = (client && client.account) || (userDetails && userDetails.account);
  const userEmail = (client?.email) || (userDetails?.email);

  const [routingNumber, setRoutingNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [receiverEmail, setReceiverEmail] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [receiverBank, setReceiverBank] = useState('');
  const [receiverAccountNumber, setReceiverAccountNumber] = useState('');
  const [description, setDescription] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [receiverZip, setReceiverZip] = useState('');

  const [verificationCode, setVerificationCode] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [transferStatus, setTransferStatus] = useState('');
  const [otpError, setOtpError] = useState('');
  const [errorSendingOtp, setErrorSendingOtp] = useState(false);

  const [nullInputs, setNullInputs] = useState(false);


  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");


  useEffect(() => {
    if (
      amount > userDetails?.balance ||
      !routingNumber || 
      !amount || 
      !receiverEmail || 
      !receiverName || 
      !receiverBank || 
      !receiverAccountNumber || 
      !description || 
      !state || 
      !city || 
      !receiverZip
    ) {
      setNullInputs(true);
    } else {
      setNullInputs(false);
    }
  }, [routingNumber, amount, receiverEmail, receiverName, receiverBank, receiverAccountNumber, description, state, city, receiverZip, userDetails?.balance]);
  

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleTransferInitiate = () => {
    notifyAdmin();
    setIsProcessing(true);
    setTransferStatus('Initiating transfer...');
    setTimeout(() => {
      sendVerificationCode();
      handleNext();
    }, 10000);
  };

  const sendVerificationCode = async () => {
    setTransferStatus("Sending OTP...");
    setOtpError('');
    setErrorSendingOtp(false);
    try {
      const response = await axios.get(`${apiUrl}/clientsByEmail?email=${userEmail}`);
      const user = response.data[0];

      if (user && user.status === 'active') {
        await axios.post(`${apiUrl}/sendVerificationCode`, {
          email: userEmail,
          receiver: `${user.firstName} ${user.lastName}`,
          account: routingNumber,
          bank: user.bank,
        });
        setTransferStatus('OTP code sent to your email.');
        openSnackbar("OTP code sent to your email.");
      } else {
        setErrorSendingOtp(false);
        setTransferStatus('User is not active. OTP cannot be sent.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setTransferStatus('Failed to send OTP. Please try again.');
      openSnackbar("Failed to send OTP. Please try again.");
      setErrorSendingOtp(true);
    }
    setIsProcessing(false);
  };

  const checkVerificationCode = async () => {
    setIsProcessing(true);
    setTransferStatus("Verifying OTP...");
    openSnackbar("Verifying OTP...");
    try {
      await axios.post(`${apiUrl}/checkVerificationCode`, {
        email: userEmail,
        code: verificationCode,
      })
      .then((response) => {
        if(response.data.message === 'Mismatch or Expired'){
          return;
        }else{
          setTransferStatus('Verification successful!');
          openSnackbar("Verification successful!");
          handleNext();
            setTransferStatus('Finishing transfer...');
            setActiveStep(2);
            openSnackbar("Finishing transfer...");
            setTimeout(() => {
              processWithdrawal();
            }, 5000);
        }
      })
    } catch (error) {
      setOtpError('Incorrect or expired verification code.');
      setErrorSendingOtp(true);
      console.error('Verification error:', error.message);
    }
    setIsProcessing(false);
  };


  const notifyAdmin = () => {
      //send email to admin and notify them about this withdrawal
      const senderName = userDetails?.firstName + " " + userDetails?.lastName;
      const senderEmail = userDetails?.email;
      const senderAccount = userDetails?.account;
      const senderBank = userDetails?.bank;

      axios
        .post(
          `${apiUrl}/notifyAdminOfTransaction?senderName=${senderName}&senderEmail=${senderEmail}&senderAccount=${senderAccount}&senderBank=${senderBank}&receiverAccount=${receiverAccountNumber}`
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
  };


  const processWithdrawal = () => {
    axios
      .post(`${apiUrl}/transfer`, {
        senderAccount: userDetails?.account,
        senderBank: userDetails?.bank,
        senderName: userDetails?.firstName + " " + userDetails?.lastName,
        senderEmail: userDetails?.email,
        senderState: userDetails?.state,
        senderCity: userDetails?.city,
        senderRouting: userDetails?.routing,
        senderZip: userDetails?.zipcode,
  
        receiverAccount: receiverAccountNumber,
        receiverBank: receiverBank,
        receiverName: receiverName,
        receiverEmail: receiverEmail,
        receiverState: state,
        receiverCity: city,
        receiverRouting: routingNumber,
        receiverZip: receiverZip,
  
        amount: amount,
        operation: "debit", // Operation for sender (debit)
        transactionType: 'wire transfer',
        description: description
      })
      .then((response) => {
        // Check if the response status is OK
        if (response.status === 200 && response.data.success) {
          const transactionId = response.data.transactionId;
          const newBalance = userDetails.balance - amount;
          updateBalance(newBalance);  
          console.log("Transaction ID:", transactionId);
          setTransferStatus('');
          setTimeout(() => {
            setTransferStatus('Transfer Successfull!');
            setActiveStep(3);
          }, 0);
          
        } else {
          // Handle the case where the transaction wasn't successful
          openSnackbar("Error finishing transfer. Please try again.");
          setActiveStep(0);
        }
      })
      .catch((error) => {
        console.error("Transaction error:", error);
        if (error.response && error.response.status === 404) {
          // Client not found
          openSnackbar("Client not found. Please check the client ID.");
          setActiveStep(0);
        } else {
          // Other errors
          openSnackbar("Operation failed. Please try again.");
          setActiveStep(0);
        }
      });
  };
  
  

  const openSnackbar = (message) => {
    setSnackbarKey((prevKey) => prevKey + 1);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const action = (
    <Button
      sx={{ color: "#fff" }}
      size="small"
      onClick={() => setSnackbarOpen(false)}
    >
      <i className="bi bi-x-lg"></i>
    </Button>
  );


  return (
    <Drawer
      anchor="bottom"
      open={openWireModal}
      onClose={handleCloseWire}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: 800,
          maxHeight: '80vh',
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          "&::-webkit-scrollbar":{
            display: 'none',
          },
          margin: 'auto',
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          boxShadow: 3,
        },
      }}
    >
       <Typography variant="h6" className='bg-light shadow-sm' gutterBottom>
          <p className='p-3'>Wire transfer </p>
          <p className='px-3' style={{fontSize:'11px', marginTop: '-38px'}}>Transfer to international bank account</p>
        </Typography>

      <Box className='p-3 mt-4'>
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ mt: 4 }}>
          {activeStep === 0 && (
            <Box>
            <Grid container spacing={2}>
              {/* Bank Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Bank Name"
                  size="small"
                  fullWidth
                  value={receiverBank}
                  onChange={(e) => setReceiverBank(e.target.value)}
                />
              </Grid>
          
              {/* Bank Routing Number */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Bank Routing Number"
                  size="small"
                  fullWidth
                  value={routingNumber}
                  onChange={(e) => setRoutingNumber(e.target.value)}
                />
              </Grid>

              {/* Bank Account Number */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Bank Account Number"
                  size="small"
                  fullWidth
                  value={receiverAccountNumber}
                  onChange={(e) => setReceiverAccountNumber(e.target.value)}
                />
              </Grid>
          
              {/* Amount */}
              
              <Grid item xs={12} sm={6}>
              <div className='mb-1'>
                <Button variant='outlined' size='small' onClick={() => setAmount(userDetails?.balance)}>100%</Button>
                <Button variant='outlined' size='small' className='mx-1' onClick={() => setAmount(userDetails?.balance * 0.75)}>75%</Button>
                <Button variant='outlined' size='small' onClick={() => setAmount(userDetails?.balance * 0.50)}>50%</Button>
                <Button variant='outlined' size='small' className='mx-1'  onClick={() => setAmount(userDetails?.balance * 0.25)}>25%</Button>
                <Button variant='outlined' size='small' color='error' onClick={() => setAmount('')}>Clear</Button>
              </div>
                <TextField
                  label="Amount"
                  type="number"
                  size="small"
                  fullWidth
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <small className='text-danger fw-bold'>{amount > userDetails?.balance && 'Insufficient balance'}</small>
              </Grid>

          
              {/* Receiver Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Receiver Name"
                  size="small"
                  fullWidth
                  value={receiverName}
                  onChange={(e) => setReceiverName(e.target.value)}
                />
              </Grid>
          
              {/* Receiver Email */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Receiver Email"
                  size="small"
                  fullWidth
                  value={receiverEmail}
                  onChange={(e) => setReceiverEmail(e.target.value)}
                />
              </Grid>
          
              {/* Receiver state */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Receiver State"
                  size="small"
                  fullWidth
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </Grid>
              {/* Receiver city */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Receiver City"
                  size="small"
                  fullWidth
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
              {/* Receiver Zipcode */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Receiver Zipcode"
                  size="small"
                  fullWidth
                  value={receiverZip}
                  onChange={(e) => setReceiverZip(e.target.value)}
                />
              </Grid>
          
              {/* Purpose */}
              <Grid item xs={12} >
                <TextField
                  label="Purpose"
                  multiline
                  rows={4}
                  size="small"
                  fullWidth
                  variant="filled"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
            </Grid>
          
            {/* Submit Button */}
            <Button
              className="mt-3"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleTransferInitiate}
              disabled={isProcessing || nullInputs}
            >
              {isProcessing ? <CircularProgress size={24} /> : "Initiate Transfer"}
            </Button>

     

            <div>
              <Button className='w-100 mt-2' variant='contained' color='error' onClick={() => handleCloseWire()}>Close</Button>
            </div>

            <div className='d-flex' style={{marginTop: '-20px'}}>
              <div>
                <img src={verified} alt="" width={150}/>
              </div>
              <div style={{marginTop: '60px', marginInline:'20px'}}>
                <p className='bg-dark px-4 shadow-sm'>IBT</p>
              </div>
            </div>
          </Box>
          
          )}

          {activeStep === 1 && (
            <Box>

              <Typography>
              {transferStatus === 'Finishing transfer...' ? (
                <div className='text-center'>
                  <CircularProgress size={24} />
                  <Typography variant="body2" mt={1}>
                    {transferStatus}
                  </Typography>
                </div>
              ) : (
                <Typography variant="body2">{transferStatus}</Typography>
              )}
              </Typography>

              {errorSendingOtp ? (
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={sendVerificationCode}
                >
                  Resend OTP
                </Button>
              ) : (
                <Box>
                  <TextField
                    label="Enter OTP"
                    fullWidth
                    margin="normal"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    error={!!otpError}
                    helperText={otpError || 'Enter the OTP sent to your email.'}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={checkVerificationCode}
                    disabled={isProcessing}
                  >
                    {isProcessing ? <CircularProgress size={24} /> : 'Verify OTP'}
                  </Button>
                </Box>
              )}
            </Box>
          )}


          {activeStep === 2 && transferStatus === 'Finishing transfer...' && (
            <Box>
            <Typography>
            {transferStatus === 'Finishing transfer...' ? (
              <Box display="flex" flexDirection="column" alignItems="center">
                <CircularProgress size={24} />
                <Typography variant="body2" mt={1}>
                  {transferStatus}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2">{transferStatus}</Typography>
            )}
            </Typography>
            {isProcessing && <CircularProgress />}
          </Box>
          )}

          
          


{activeStep === steps.length && (
  <Card elevation={2} sx={{ padding: 3, textAlign: 'center', maxWidth: 400, margin: '0 auto' }}>
    {activeStep === 3 ? (
      <>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={2}>
          <Box display="flex" alignItems="center" justifyContent="center" color="seagreen">
            <CheckCircleIcon sx={{ fontSize: 48 }} />
          </Box>
          <Typography variant="h6" color="success.main">
            Transfer Successful!
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Your transaction has been completed successfully. You can view the receipt for details.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={{ mt: 2 }}
            onClick={() => history.push('/history')}
          >
            View Receipt
          </Button>
        </Box>
      </>
    ) : (
      <Typography variant="h6" color="info.main">
        {transferStatus}
      </Typography>
    )}
  </Card>
)}

        </Box>
      </Box>

      {/* Alert box */}
      <Snackbar
        key={snackbarKey}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={action}
      />
    </Drawer>
  );
};

export default WireTransfer;




      