import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-credit-cards/es/styles-compiled.css';
import Cards from 'react-credit-cards';
import { Modal, Button } from 'react-bootstrap';
import { useUser } from "../components/UserContext";
import cc from "../images/cc.jpg";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';




export default function Card() {
  const {user: userDetails} = useUser();
  const firstName = userDetails && userDetails.firstName;
  const lastName = userDetails && userDetails.lastName;
  const [masterIsChecked, setMasterIsChecked] = useState(false);
  const [visaIsChecked, setVisaIsChecked] = useState(false);
  const [AEIsChecked, setAEIsChecked] = useState(false);
  const [cardDetails, setCardDetails] = useState(null);

  const apiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL_PROD;

  const [state, setState] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    issuer: '',
    acceptedCards: '',
    bankName: 'TOWNLINK BANK'
  });


  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  

  const handleMasterChecked = (event) => {
    setMasterIsChecked(event.target.checked);
    setVisaIsChecked(false);
    setAEIsChecked(false);
    if (event.target.checked) {
      const details = {
        expiry: "0427",
        focused: state.focus,
        name: firstName + " " + lastName,
        number: 5509827364905768,
        type: 'Master Card',
        bankName: state.bankName
      };
      setCardDetails(details);
    } else {
      setCardDetails(null);
    }
  };
  const handleVisaChecked = (event) => {
    setVisaIsChecked(event.target.checked);
    setMasterIsChecked(false);
    setAEIsChecked(false);
    if (event.target.checked) {
      const details = {
        expiry: "0427",
        focused: state.focus,
        name: firstName + " " + lastName,
        number: 4545983667903744,
        type: 'Visa Card',
        bankName: state.bankName
      };
      setCardDetails(details);
    } else {
      setCardDetails(null);
    }
  };
  const handleAEChecked = (event) => {
    setAEIsChecked(event.target.checked);
    setVisaIsChecked(false);
    setMasterIsChecked(false);
    if (event.target.checked) {
      const details = {
        expiry: "0427",
        focused: state.focus,
        name: firstName + " " + lastName,
        number: 346242798765453,
        type: 'American Express Card',
        bankName: state.bankName
      };
      setCardDetails(details);
    } else {
      setCardDetails(null);
    }
  };
  
  
  return (
    <div id="PaymentForm" className="card border-0">
      <div className="pt-4 px-4">
        <h4>Card types</h4>
        <h5>You can choose from any of the cards</h5>
      </div>

      <div className="container creditCards">
        <div className="creditCards-items">
        <div className={`position-relative credit-card me-3 checks ${masterIsChecked && 'checked'}`}>
            <Cards
              cvc={state.cvc}
              expiry={"0427"}
              focused={state.focus}
              name={firstName + " " + lastName}
              number={5509827364905768}
              issuer={state.issuer}
              bankName={state.bankName}
            />
            <div className="bank-name">{state.bankName}</div>
            <div className="form-check form-switch ms-5">
              <input
                // disabled={(visaIsChecked || AEIsChecked) && true}
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={masterIsChecked}
                onChange={handleMasterChecked}
              />
              <label
                className="form-check-label text-body"
                htmlFor="flexSwitchCheckDefault"
              >
                {masterIsChecked ? 
                <p className='text-success'>
                  Selected <i className='bi bi-check-all'></i>
                </p> : "Select this card"}
              </label>
            </div>
          </div>

          <div className={`position-relative credit-card text-light me-3 checks ${visaIsChecked && 'checked'}`}>
            <Cards
              cvc={state.cvc}
              expiry={"0427"}
              focused={state.focus}
              name={firstName + " " + lastName}
              number={4545983667903744}
              issuer={state.issuer}
              bankName={state.bankName}
            />
            <div className="bank-name">{state.bankName}</div>
            <div className="form-check form-switch ms-5">
              <input
                // disabled={(masterIsChecked || AEIsChecked) && true}
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={visaIsChecked}
                onChange={handleVisaChecked}
              />
              <label
                className="form-check-label text-body"
                htmlFor="flexSwitchCheckDefault"
              >
                {visaIsChecked ? 
                <p className='text-success'>
                  Selected <i className='bi bi-check-all'></i>
                </p> : "Select this card"}
              </label>
            </div>
          </div>

          <div className={`position-relative credit-card text-light checks ${AEIsChecked && 'checked'}`}>
            <Cards
              cvc={state.cvc}
              expiry={"0429"}
              focused={state.focus}
              name={firstName + " " + lastName}
              number={346242798765453}
              issuer={state.issuer}
              bankName={state.bankName}
            />
            <div className="bank-name">{state.bankName}</div>

            <div className="form-check form-switch ms-5">
              <input
                // disabled={(masterIsChecked || visaIsChecked) && true}
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={AEIsChecked}
                onChange={handleAEChecked}
              />
              <label
                className="form-check-label text-body"
                htmlFor="flexSwitchCheckDefault"
              >
                {AEIsChecked ? 
                <p className='text-success'>
                  Selected <i className='bi bi-check-all'></i>
                </p> : "Select this card"}
              </label>
            </div>
          </div>
        </div>
      </div>
      


      <div className="px-4 pt-5 instruction">
        <h4>How to activate and order card </h4>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
          neque doloribus consequuntur iste voluptatem facilis, repellat commodi
          dicta ab possimus, numquam, aspernatur magnam. Corporis ipsa id, ab
          ullam animi unde.
        </p>

       {(masterIsChecked || visaIsChecked || AEIsChecked) && (
         <div className="mt-5">
         <h4>Selected Card</h4>

         <div className="d-flex justify-content-between my-4 border-bottom">
           <p>NAME</p>
          <p>{cardDetails && cardDetails.name}</p>
          <p>accepted</p>
         </div>

         <div className="d-flex justify-content-between my-4 border-bottom">
           <p>CARD NO.</p>
          <p>{cardDetails && cardDetails.number}</p>
          <p>accepted</p>
         </div> 

         <div className="d-flex justify-content-between my-4 border-bottom">
           <p>TYPE</p>
          <p>{cardDetails && cardDetails.type}</p>
          <p>accepted</p>
         </div>

         <div className="d-flex justify-content-between my-4 border-bottom">
           <p>VALID TILL</p>
          <p>{cardDetails && cardDetails.expiry}</p>
          <p>accepted</p>
         </div> 

         <div className="d-flex justify-content-between my-4 border-bottom">
           <p>CVC</p>
          <p>_______</p>
          <p>not assigned yet</p>
         </div>

       </div>
       )}

        <div className="image">
            <img src={cc} alt="" />
            <div className="">
        {masterIsChecked ? (
          <div className='text-center'>
            <p>Activate your Master card</p>
            <Button className="" onClick={() => setShowModal(true)}>
            Activate and order card
          </Button>
          </div>
        ) : visaIsChecked ? (
          <div className='text-center'>
            <p>Activate your Visa card</p>
            <Button className="" onClick={() => setShowModal(true)}>
            Activate and order card
          </Button>
          </div>
        ) : AEIsChecked ? (
          <div className='text-center'>
            <p>Activate your American Express card</p>
            <Button className="" onClick={() => setShowModal(true)}>
            Activate and order card
          </Button>
          </div>
        ) : ""}
      </div>
        </div>
      </div>

    

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        aria-labelledby="exampleModalToggleLabel2"
        backdrop="static"
      >
        <Modal.Header closeButton className="bg-light">
          <Modal.Title id="exampleModalToggleLabel2">Activate Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="modal-title mb-4" id="usdModalLabel">
            Activate and order {cardDetails && cardDetails.type}
          </h5>


          <div className="position-relative credit-card mb-3 selected" >
            <Cards
              cvc={state.cvc}
              expiry={cardDetails && cardDetails.expiry}
              focused={state.focus}
              name={cardDetails && cardDetails.name}
              number={cardDetails && cardDetails.number}
              issuer={cardDetails && cardDetails.type}
              bankName={cardDetails && cardDetails.bankName}
            />
            <div className="bank-nam position-relative" >
            </div>
          </div>








































          
          <div className="d-flex">
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Amount ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Card
                    </TableCell>
                    <TableCell align="right">200</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Shipping
                    </TableCell>
                    <TableCell align="right">50</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      VAT
                    </TableCell>
                    <TableCell align="right">10</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row" className="fw-bolder">
                      TOTAL
                    </TableCell>
                    <TableCell align="right" className="fw-bolder">
                      260
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="mt-4">
            <p>Shipping to: {userDetails && userDetails.street}, {userDetails && userDetails.state} {userDetails && userDetails.country}.</p>
            <h5>Change shipping Address</h5>
            <input type="text" className="form-control" />
            <Button className="mt-3">Send</Button>
          </div>

          <div className="mt-4">
            <h5>Make payment </h5>
            <p>
              Make payment to the below <code>Bitcoin address</code> to activate
              and order your credit card, note, card is expected to arive to
              owner country in less than 3 days.
            </p>
            <code>0987364789098367890</code>
            <p>
              Email us with your prove of payment to fast forward the proceess
              if you have completed the payment
            </p>
            <a href="mailto:support@townlinkbank.com">
              I have Completed the payment
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <br />
      <br />
    </div>
  );
}
