import React, { useState, useEffect } from "react";
import {
  Drawer,
  Typography,
  IconButton,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "../UserContext";
import Snackbar from "@mui/material/Snackbar";

const Credit = ({ onClose, selectedClient }) => {
    const { user: userDetails } = useUser();
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const [accountNumber, setAccountNumber] = useState((selectedClient?.account ) || '');
  const [fullName, setFullName] = useState( selectedClient?.firstName + " " + selectedClient?.lastName|| "");    
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");

  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [routing, setRouting] = useState("");
  const [zipcode, setZipcode] = useState("");


  const [isClientFound, setIsClientFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSearchClient = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    setIsClientFound(false);
    setLoading(true);

    try {
      const response = await fetch(`${apiUrl}/clients/${accountNumber}`);
      if (!response.ok) {
        throw new Error("Failed to fetch client");
      }
      const data = await response.json();

      if (data.length > 0) {
        const { firstName, lastName, email, state, city, routing, zipcode } = data[0];

        setFullName(`${firstName} ${lastName}`);
        setEmail(`${email}`)
        setState(`${state}`)
        setCity(`${city}`)
        setRouting(`${routing}`)
        setZipcode(`${zipcode}`)

        setIsClientFound(true);
        setSuccessMessage("Client Found Successfully!");
      } else {
        setFullName("");
        setIsClientFound(false);
        setErrorMessage("No Client Found")
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("An error occurred while searching for the client.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
  
    const transactionData = {
      senderAccount: userDetails?.account,
      senderBank: 'Townlink bank',
      senderName: `${userDetails?.firstName} ${userDetails?.lastName}`,
      senderEmail: userDetails?.email,
      senderState: userDetails?.state,
      senderCity: userDetails?.city,
      senderRouting: userDetails?.routing,
      senderZip: userDetails?.zipcode,
      receiverAccount: accountNumber,
      receiverBank: "Townlink bank",
      receiverName: fullName,
      receiverEmail: email,
      receiverState: state,
      receiverCity: city,
      receiverRouting: routing,
      receiverZip: zipcode,
      amount,
      operation: "debit",
      transactionType: "wire transfer",
      description,
    };
  
    try {
      const response = await fetch(`${apiUrl}/transactions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(transactionData),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        setTimeout(() => {
            openSnackbar("Transaction successful!");
            setLoading(false);
            setAccountNumber('');
            setFullName('');
            setAmount('');
            setDescription('');
        }, 3000);
      } else {
        setErrorMessage(result.error || "Transaction failed.");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("An error occurred while processing the transaction.");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  const openSnackbar = (message) => {
    setSnackbarKey((prevKey) => prevKey + 1);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const action = (
    <Button
      sx={{ color: "#fff" }}
      size="small"
      onClick={() => setSnackbarOpen(false)}
    >
      <i className="bi bi-x-lg"></i>
    </Button>
  );
  

  return (
    <Drawer
      anchor="bottom"
      open={true}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          maxWidth: 800,
          maxHeight: "80vh",
          overflowY: "scroll",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          margin: "auto",
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          boxShadow: 3,
        },
      }}
    >
      <div
        className="rounded-top bg-light shadow-sm"
        style={{ padding: 10, position: "fixed", width: "100%", maxWidth: "800px", zIndex: 99 }}
      >
        <div className="d-flex justify-content-between">
            <div>
            <Typography variant="h5" className="text-capitalize">Debit Client</Typography>
            <Typography variant="p" className="text-capitalize">{ fullName === 'undefined undefined' ? '' : fullName } </Typography>
            </div>
          <div>
          <IconButton onClick={onClose} className="bg-danger-subtle text-danger">
            <CloseIcon />
          </IconButton>
          </div>
        </div>
      </div>
      <div className="px-2" style={{ marginTop: 105 }}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {successMessage && <Alert severity="success">{successMessage}</Alert>}

        <Box display="flex" flexDirection="column" gap={2} mt={2} mb={4}>
          <TextField
            label="Account Number"
            variant="outlined"
            fullWidth
            size='small'
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            size='small'
            onClick={handleSearchClient}
            disabled={loading || !accountNumber}
          >
            {loading ? <CircularProgress size={20} /> : "Search Client"}
          </Button>

          <TextField
            label="Full Name"
            variant="outlined"
            fullWidth
            size='small'
            value={ fullName === 'undefined undefined' ? '' : fullName } 
            onChange={(e) => {
              if (!isClientFound) {
                setFullName(e.target.value);
              }
            }}
            InputProps={{
              readOnly: isClientFound,
            }}
            placeholder={!isClientFound ? "Enter full name manually" : ""}
          />
          <TextField
            label="Amount"
            type="number"
            variant="outlined"
            fullWidth
            size='small'
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <TextField
            label="Your Message"
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            size='small'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            />
            <small style={{ marginBlock: -9 }}>
            {fullName && amount && (
                <>
                You are Debiting <b>{fullName}</b> the amount of <b>${amount}</b>
                </>
            )}
            </small>
            {!loading ? (
                <Button
                variant="contained"
                color="error"
                onClick={handleSubmit}
                disabled={
                  !accountNumber ||
                  !fullName ||
                  !amount ||
                  (!isClientFound && fullName.trim() === "")
                }
              >
                Credit
              </Button>
            ) : (
                <Button
                variant="contained"
                color="success"
                disabled>
                please wait... <span className="spinner-border spinner-border-sm"></span>
            </Button>
            )}
        </Box>
      </div>


      <Snackbar
          key={snackbarKey}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          action={action}
        />

     
    </Drawer>
  );
};

export default Credit;
