import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const NotFound = () => {
const history = useHistory();
    return ( 
        <div className="d-flex justify-content-center bg-white py-5">
            <div className="text-center text-muted mt-5">
                <i className="bi bi-search text-danger" style={{fontSize:  "2rem"}}></i>
                {/* <h4 className="mt-3">404</h4> */}
                <h4 className="mt-3">Page Not Found</h4>
                <p>The page you are trying to access <br/> might have been removed or changed</p>
                <button className="btn text-danger bg-danger-subtle" onClick={() => history.goBack()}>Go Back</button>
            </div>
        </div>
     );
}
 
export default NotFound;