import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";


const ContactForm = () => {
  const location = useLocation();
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [error, setError] = useState("");

  const [regReq, setRegReq] = useState(false);
  const [reqLogin, setReqLogin] = useState(false);

  const handleSubmit = async (event) => {
    setSending(true);
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await axios.post(
        // "https://formsubmit.co/ajax/ucboi1.up@gmail.com",
        "https://formsubmit.co/ajax/support@townlinkbank.com",
        Object.fromEntries(formData)
      );

      console.log("Form submission successful:", response);
      setSending(false);
      setMessageSent(true);
      setError("");
      form.reset();
      // Redirect to the specified URL
      history.push("/contactForm");
    } catch (error) {
      console.error("Error submitting form:", error);
      setSending(false);
      setMessageSent(false);
      setError("Error submitting form:", error);
    }
  };

  useEffect(() => {
    if (messageSent || error) {
      setTimeout(() => {
        setMessageSent(false);
        setError("");
      }, 2000);
    }
  }, [messageSent, error]);

  //check if user is from reqReg page for registration request
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get("source");
    if (source === "reqReg") {
      setRegReq(true);
    }
    if (source === "reqLogin") {
      setReqLogin(true);
    }
  }, [location]);

 

  return (
    <div className="container contactComponent">

      <div className="row py-5">
        <div className="col-md-6 offset-md-3">
          {regReq ? (
            <h4 className="mb-5"> Registration Request</h4>
          ) : reqLogin ? (
            <h4 className="mb-5"> Password Reset Request</h4>
          ) : (
            <h4 className="mb-5">Contact Form</h4>
          )}
          <form
            onSubmit={handleSubmit}
            method="POST"
            encType="multipart/form-data"
          >
            <input type="hidden" name="_captcha" value="false" />
            <input type="text" name="_honey" style={{ display: "none" }} />

            {regReq ? (
              <div>
              <div className="row">
                <div className="col-6 col-sm-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Full Name"
                      required
                    />
                    <label htmlFor="floatingInput">Full Name</label>
                  </div>
                </div>

                <div className="col-6 col-sm-6">
                  <div className="">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Email"
                        required
                      />
                      <label htmlFor="floatingInput">Email</label>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-sm-4">
                  <div className="">
                    <div className="form-floating mb-3">
                      <input
                        type="number"
                        name="Phone"
                        className="form-control"
                        id="floatingInput"
                        placeholder="PhoneNumber"
                        required
                      />
                      <label htmlFor="floatingInput">Phone Number</label>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-sm-4">
                  <div className="">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="Gender"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Gender"
                      />
                      <label htmlFor="floatingInput">Gender</label>
                    </div>
                  </div>
                </div>

                <div className="col-4 col-sm-4">
                  <div className="">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="Country"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Country"
                      />
                      <label htmlFor="floatingInput">Country</label>
                    </div>
                  </div>
                </div>

                <div className="col-5 col-sm-6">
                  <div className="">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="State"
                        className="form-control"
                        id="floatingInput"
                        placeholder="State"
                      />
                      <label htmlFor="floatingInput">State</label>
                    </div>
                  </div>
                </div>

                <div className="col-3 col-sm-6">
                  <div className="">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="City"
                        className="form-control"
                        id="floatingInput"
                        placeholder="City"
                      />
                      <label htmlFor="floatingInput">City</label>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-sm-4">
                  <div className="">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="Street"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Street"
                      />
                      <label htmlFor="floatingInput">Street</label>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-sm-4">
                  <div className="">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="zipCode"
                        className="form-control"
                        id="floatingInput"
                        placeholder="zipCode"
                      />
                      <label htmlFor="floatingInput">zipCode</label>
                    </div>
                  </div>
                </div>

                <div className="col-12 ">
                  <div className="">
                    <small className="text-danger">
                      Is there any other information you think we should know?
                    </small>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="otherInfo"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Other Info"
                      />
                      <label htmlFor="floatingInput">Other Info</label>
                    </div>
                  </div>
                </div>

                <p>
                  Use this service to share your passport photo with us:{" "}
                  <a href="https://safenote.co/upload-file">
                    https://safenote.co/upload-file
                  </a>
                </p>
                <p>
                  Instruction: upload your passport, copy the url and paste it
                  below
                </p>

                <div className="">
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="text"
                      name="passport-URL"
                      id="floatingInput"
                      placeholder="Paste passport URL here"
                    />
                    <label htmlFor="floatingInput">
                      Paste passport URL here
                    </label>
                  </div>
                </div>

                <p className="text-center">OR</p>

                <div className="col-12 ">
                  <div className="">
                    <div className="form-floating mb-3">
                      <input
                        type="file"
                        name="attachment"
                        className="form-control"
                        id="floatingInput"
                        placeholder="passport"
                        accept="image/png, image/jpeg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

              
            ) : reqLogin ? (
             <div>
              <p>We will send you a new password to your registerd email after verification is passed.</p>
              <div className="row gx-1">
                <div className="form-floating mb-3 col-6">
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    id="email"
                    placeholder="Your Email"
                    required
                  />
                  <label htmlFor="email">Your Email</label>
                </div>

                <div className="form-floating mb-3 col-6">
                  <input
                    type="number"
                    name="Support-Pin"
                    className="form-control"
                    id="supportPin"
                    placeholder="support Pin"
                    required
                  />
                  <label htmlFor="email">Support Pin</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="password"
                    name="Previous-Password"
                    className="form-control"
                    id="password"
                    placeholder="Previous Password"
                    required
                  />
                  <label htmlFor="email">Previous Password</label>
                  <small>Enter the last thing you can remeber as your current password</small>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="password"
                    name="New-Password"
                    className="form-control"
                    id="password"
                    placeholder="New Password"
                    required
                  />
                  <label htmlFor="email">New Password</label>
                </div>

  
              </div>             
              </div>
            ) : (
              
            <div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Your Name"
                    required
                  />
                  <label htmlFor="floatingInput">Your Name</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    id="email"
                    placeholder="Your Email"
                    required
                  />
                  <label htmlFor="email">Your Email</label>
                </div>

                <div className="form-floating">
                  <textarea
                    className="form-control"
                    name="message"
                    placeholder="Further Comments"
                    id="floatingTextarea"
                    required
                  ></textarea>
                  <label htmlFor="floatingTextarea">Further Comments</label>
                </div>
              </div>
            )}

            {!sending ? (
              <Button variant="contained" className="btn mt-3">
                {regReq ? "send reg. request" : reqLogin ? "send login" : "send message"}{ " " }
                <i className="bi bi-send"></i>
              </Button>
            ) : (
              <button type="submit" className="btn mt-3 px-5 disabled">
                Sending...{" "}
                <i className="spinner spinner-border spinner-border-sm ms-3"></i>
              </button>
            )}

          </form>

          <div>
            {messageSent && (
              <div className="pb-5 mt-3 mb-5">
                <div className="bg-light rounded border p-3 ">
                  <p className="m-auto text-success">
                    <i className="bi bi-check2-all "></i> Message Sent
                    successfully
                  </p>
                </div>
                <br />
              </div>
            )}

            {error && (
              <div className="pb-5 mt-3 mb-5 ">
                <div className="bg-light rounded border border-danger p-3">
                  <p className="m-auto text-danger">
                    <i className="bi bi-exclamation-circle-fill me-2"></i>
                    {error}
                  </p>
                </div>
                <br />
              </div>
            )}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default ContactForm;
