import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import MoreVert from "@mui/icons-material/MoreVert";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import ArrowCircleBack from '@mui/icons-material/ArrowCircleLeft';
import Receipt from "@mui/icons-material/Receipt";
import Close from "@mui/icons-material/Close";
import Image from "@mui/icons-material/Image";
import Share from "@mui/icons-material/Share";
import Error from "@mui/icons-material/Error";
import axios from "axios";
import moment from "moment";
import { useUser } from "./UserContext";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import cover from "../images/logo.png";
import Snackbar from "@mui/material/Snackbar";

const HistoryTable = () => {
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const location = useLocation();
  const history = useHistory();
  const client = location.state && location.state.client;
  const { user: userDetails } = useUser();
  const userAccount =
    (client && client.account) || (userDetails && userDetails.account);

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [receipt, setReceipt] = useState(null);
  const [receiptOpen, setReceiptOpen] = useState(false);
  const [formValues, setFormValues] = useState({});

  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const printArea = useRef();



  useEffect(() => {
    if (userAccount) {
      axios
        .get(`${apiUrl}/history/${userAccount}`)
        .then((response) => {
          const transactions = response.data;
          console.log(response.data);
          setTransactions(transactions);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
          setLoading(false);
        });
    }
  }, [apiUrl, userAccount]);





  const handleMenuClick = (event, transaction) => {
    setAnchorEl(event.currentTarget);
    setSelectedTransaction(transaction);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditOpen = (transaction) => {
    setFormValues(transaction);
    setEditDialogOpen(true);
    handleMenuClose();
  };

  const handleEditSave = () => {
    // Specify the allowed fields for updates
    const allowedFields = [
      "account",
      "name",
      "bank",
      "amount",
      "operation",
      "transactionType",
      "reference",
      "description",
      "date",
      "time",
    ];
  
    // Filter formValues to include only allowed fields
    const filteredValues = Object.keys(formValues)
      .filter((key) => allowedFields.includes(key))
      .reduce((obj, key) => {
        obj[key] = formValues[key];
        return obj;
      }, {});
  
    // Include the `id` for the backend to identify the record
    if (!formValues.id) {
      alert("Transaction ID is missing.");
      return;
    }
    filteredValues.id = formValues.id;
  
    axios
      .put(`${apiUrl}/history/${formValues.id}`, filteredValues)
      .then(() => {
        setEditDialogOpen(false);
        openSnackbar("Transaction updated successfully.")
        axios
        .get(`${apiUrl}/history/${userAccount}`)
        .then((response) => {
          const transactions = response.data;
          console.log(response.data);
          setTransactions(transactions);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
          setLoading(false);
        });
      })
      .catch((err) => {
        console.error(err);
        alert("Failed to update transaction.");
      });
  };
  
  

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this transaction?")) {
      axios
        .delete(`${apiUrl}/history/${id}`)
        .then(() => {
          openSnackbar("Transaction updated successfully.")
        axios
        .get(`${apiUrl}/history/${userAccount}`)
        .then((response) => {
          const transactions = response.data;
          console.log(response.data);
          setTransactions(transactions);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
          setLoading(false);
        });
        })
        .catch((err) => {
          console.error(err);
          alert("Failed to delete transaction.");
        });
      handleMenuClose();
    }
  };

  const handleViewReceipt = (selectedTransaction) => {
    setReceipt(selectedTransaction)
    setReceiptOpen(true);
    handleMenuClose();
  };

  

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


   // Handle receipt printing to PDF
   const handlePrintReceipt = () => {
    const content = printArea.current;
    if (content) {
      html2canvas(content, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("receipt.pdf");
        openSnackbar("Receipt downloaded as PDF!");
      });
    } else {
      openSnackbar("An Error Occurred!");
    }
  };

  const handleShare = async () => {
    try {
      const receiptElement = printArea.current; // Reference to the receipt div
      if (!receiptElement) {
        throw new Error("Receipt element not found");
      }

      // Convert the receipt div into a canvas
      const canvas = await html2canvas(receiptElement, {
        scale: 2, // Improve quality
        useCORS: true, // Enable cross-origin support for images
      });

      // Convert the canvas to a data URL and then to a Blob
      const dataUrl = canvas.toDataURL("image/png");
      const blob = await (await fetch(dataUrl)).blob();

      // Prepare the file for sharing
      const file = new File([blob], "transaction-receipt.png", { type: "image/png" });

      // Check if the browser supports file sharing
      if (navigator.share && navigator.canShare({ files: [file] })) {
        await navigator.share({
          title: "Transaction Receipt",
          text: "Here is your transaction receipt.",
          files: [file],
        });
        console.log("Receipt shared successfully!");
      } else {
        alert("Sharing is not supported on this device.");
      }
    } catch (error) {
      console.error("Error sharing the receipt:", error);
    }
  };

  const openSnackbar = (message) => {
    setSnackbarKey((prevKey) => prevKey + 1);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const action = (
    <Button
      sx={{ color: "#fff" }}
      size="small"
      onClick={() => setSnackbarOpen(false)}
    >
      <i className="bi bi-x-lg"></i>
    </Button>
  );

  if(receiptOpen){
    return(
     <div className="shadow border rounded p-3" style={{width:'100%', maxWidth:'400px', margin:'100px auto'}}>
       <div className="receipt">
     

      <div ref={printArea} className="p-3">
        <div className="receipt-header">
          <div className="d-flex justify-content-between">
            <h6 className="mt-3">Transaction <br /> Receipt</h6>
            <img className="mt-auto" src={cover} alt="" width={80} />
          </div>
        </div>

        <div className="body">
          <div className="mt-3 bg-light p-3 rounded-top text-center text-capitalize">
            <p style={{fontSize: 13, fontWeight: 900}}>{receipt?.operation} transaction</p>
            <h5 style={{ fontSize: '30px', fontWeight: 900 }}>{receipt?.operation === 'credit' ? '+' : '-'} ${receipt?.amount}</h5>
            <p style={{ marginTop: '-10px', fontSize: '10px' }}>
              {moment(receipt?.date).format("DD MMM YYYY")} - {moment(receipt?.time, "HH:mm:ss").format("h:mmA")}
            </p>
          </div>

          <div className="mt-3 bg-light p-3 d-flex justify-content-between">
            <div>
              <small>Transaction Date & Time</small> <br />
              <small>Transaction Reference</small>
            </div>

            <div>
              <small>{moment(receipt?.date).format("DD MMM YYYY")} - {moment(receipt?.time, "HH:mm:ss").format("h:mmA")}</small><br />
              <small>{receipt?.reference}</small>
            </div>
          </div>

          <div className="p-2">
            <small>{receipt?.role === 'sender' ? 'Receiver Details' : 'Sender Details'}</small>
          </div>

            
          <div className="mt-1 p-3 d-flex justify-content-between" style={{ background: '#fbfbfb' }}>
            <div>
              <small> Account Number</small><br />
              <small>Bank Name</small><br />
              {receipt?.transactionType === 'wire transfer' && <small>Routing Number</small>}<br />
              <small>Transaction Type</small>
            </div>

            <div className="text-capitalize">
              <small>{receipt?.relatedParty.account}</small><br />
              <small>{receipt?.relatedParty.bank}</small><br />
              {receipt?.transactionType === 'wire transfer' && <small>{receipt?.relatedParty.routing}</small>}<br />
              <small>{receipt?.transactionType}</small>
            </div>
          </div>

          <div className="mt-1 p-3 d-flex justify-content-between bg-light rounded-bottom">
            <small>Description:</small>
            <small>{receipt?.description}</small>
          </div>

          <div className="text-center mt-3 m-auto" style={{ width: '250px' }}>
            <small style={{fontSize:'9px'}}>For complaints regarding this transaction, contact our support team.</small>
          </div>
        </div>
      </div>
    </div>

    <div className="p-4 ">
    <Button onClick={() => setReceiptOpen(false)} variant="" className="me-2 bg-secondary text-light text-capitalize">Close <Close/></Button>
        <Button onClick={handlePrintReceipt} variant="contained" color="primary" className="me-2 text-capitalize">Save <Image/></Button>
        <Button onClick={handleShare} variant="contained" className="text-capitalize bg-secondary text-light">share <Share/></Button>

    </div>

     </div>
    )
  }


  if(history.length < 0){
    return(
      <div className="mt-5 pt-3">
        <div className="p-3 mt-5 d-flex justify-content-center">
          <div className="border shadow-sm rounded p-5 text-center">
            <Error sx={{fontSize:40, color:'#a03'}}/>
            <h6>Sorry, you have not made any <br/> transaction yet</h6>
            <small>All your transaction history will appear here</small>
             <div className='p-3'> 
              <IconButton className="bg-light" onClick={() => history.goBack()}><ArrowCircleBack/></IconButton>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={{marginTop:'40px'}}>
      <br /><br /><br />
      <div className="mx-3 mx-md-5 border shadow-sm rounded" style={{paddingInline:"20px", margin:'auto'}}>
      <br />
      <br />
     
      <div className="d-flex flex-wrap" style={{fontSize: '12px'}}>
      {/* Labels */}
      <div className="me-4 text-start">
        <p>Full Name:</p>
        <p>Account Number:</p>
        <p>Account Type:</p>
        <p>Routing:</p>
        <p>Swift Code:</p>
      </div>

      {/* Values */}
      <div className="text-capitalize">
        <p>{userDetails?.firstName + " " + userDetails?.lastName}</p>
        <p>{userDetails?.account}</p>
        <p>{userDetails?.accountType || 'N/A'}</p>
        <p>{userDetails?.routing}</p>
        <p>{userDetails?.swiftCode}</p>
      </div>
    </div>


      {loading ? (
        <div style={{ textAlign: "center", margin: "20px" }}>
          <CircularProgress />
        </div>
      ) : error ? (
        <div style={{ textAlign: "center", margin: "20px" }}>{error}</div>
      ) : (
        <>
          <TableContainer className="p- history-table">
            <Table>
              <TableHead>
                <TableRow >
                  <TableCell className="fw-bold text-muted">Menu</TableCell>
                  <TableCell className="fw-bold text-muted">Amount</TableCell>
                  <TableCell className="fw-bold text-muted">Transaction Type</TableCell>
                  <TableCell className="fw-bold text-muted">Sender</TableCell>
                  <TableCell className="fw-bold text-muted">Receiver</TableCell>
                  <TableCell className="fw-bold text-muted">Description</TableCell>
                  <TableCell className="fw-bold text-muted">Time</TableCell>
                  <TableCell className="fw-bold text-muted">Status</TableCell>
                </TableRow>
              </TableHead>
              
                <TableBody>
                  {transactions.map((transaction, index) => {
                    const isSender = transaction.role === "sender";
                    const isReceiver = transaction.role === "receiver";

                    return (
                      <TableRow key={transaction.id || index}>
                         <TableCell>
            <IconButton
              size="small"
              onClick={(e) => handleMenuClick(e, transaction)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => handleViewReceipt(selectedTransaction)}
              >
                <Receipt /> View Receipt
              </MenuItem>
              {userDetails?.role === 'admin' && (
                <>
                  <MenuItem
                onClick={() => handleEditOpen(selectedTransaction)}
              >
                <Edit /> Edit
              </MenuItem>
              <MenuItem
                onClick={() => handleDelete(selectedTransaction?.id)}
              >
                <Delete /> Delete
              </MenuItem>
                </>
              )}
            </Menu>
          </TableCell>

          <TableCell className={transaction.operation === 'credit' ? 'text-success ' : 'text-danger'}>
        
        <span className={transaction.operation === 'credit' ? 'bg-success-subtle text-success px-2 rounded' : "bg-danger-subtle text-danger px-2 rounded"}>
        {transaction.operation === 'credit' ? `+${transaction.amount}` : `-${transaction.amount}`}
        </span>
      </TableCell>
                        <TableCell className="table-body-cell text-capitalize">
                          {transaction.transactionType || "N/A"}
                        </TableCell>
                        <TableCell className="table-body-cell text-capitalize">
                          {isReceiver
                            ? transaction.relatedParty?.name || "Unknown Sender"
                            : transaction.name}
                        </TableCell>
                        <TableCell className="table-body-cell text-capitalize">
                          {isSender
                            ? transaction.relatedParty?.name || "Unknown Receiver"
                            : transaction.name}
                        </TableCell>
                        <TableCell className="table-body-cell text-capitalize">
                          {transaction.description || ""}
                        </TableCell>
                        <TableCell className="table-body-cell">
                        {moment(receipt?.date).format("DD MMM YYYY")} | {' '}
                        {transaction.time
                        ? moment(transaction.time, "HH:mm:ss").format("h:mm A")
                        : "Invalid Time"}
                        </TableCell>
                        <TableCell className="table-body-cell text-capitalize text-success">
                          {transaction.status || "N/A"}
                        </TableCell>

                       
                      </TableRow>
                    );
                  })}
                </TableBody>



            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={transactions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      {/* Edit Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
  <DialogTitle>Edit Transaction</DialogTitle>
  <DialogContent>
    {Object.keys(formValues)
      .filter((key) => [
        "account",
        "name",
        "bank",
        "amount",
        "operation",
        "transactionType",
        "reference",
        "description",
        "date",
        "time",
      ].includes(key)) // Only include these keys
      .map((key) => (
        <TextField
          fullWidth
          key={key}
          label={key}
          margin="dense"
          value={formValues[key] || ""}
          onChange={(e) =>
            setFormValues({ ...formValues, [key]: e.target.value })
          }
        />
      ))}
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
    <Button onClick={() => handleEditSave(formValues)} color="primary">
      Save
    </Button>
  </DialogActions>
</Dialog>





    </div>



 {/* Alert box */}
 <Snackbar
        key={snackbarKey}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={action}
      />
    
    </div>
  );
};

export default HistoryTable;
