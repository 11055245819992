import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import App from './App';
import { UserProvider } from './components/UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>
      <App />
    </UserProvider>
  </React.StrictMode>
);

// Register the service worker
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.unRegister('/service-worker.js').then(registration => {
//       console.log('ServiceWorker registration successful with scope: ', registration.scope);

//       registration.onupdatefound = () => {
//         const installingWorker = registration.installing;
//         installingWorker.onstatechange = () => {
//           if (installingWorker.state === 'installed') {
//             if (navigator.serviceWorker.controller) {
//               console.log('New content is available, please refresh.');
//               const confirm = window.confirm('A new version is available. Do you want to reload the page to update?');
//               if (confirm) {
//                 window.location.reload(); 
//               }
//             } else {
//               console.log('Content is cached for offline use.');
//             }
//           }
//         };
//       };
//     }).catch(error => {
//       console.log('ServiceWorker registration failed: ', error);
//     });
//   });
// }


