// // src/firebaseConfig.js
// import { initializeApp } from "firebase/app";
// import { getDatabase } from "firebase/database";

// const firebaseConfig = {
//   apiKey: "AIzaSyDS7m1kItxD5MfOB4vKcS-75FRaDN_qcMo",
//   authDomain: "test-76df5.firebaseapp.com",
//   databaseURL: "https://test-76df5-default-rtdb.firebaseio.com/",
//   projectId: "test-76df5",
//   storageBucket: "test-76df5.appspot.com",
//   messagingSenderId: "497868115872",
//   appId: "1:497868115872:web:464084aa3d8db03fa3dec5"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const database = getDatabase(app);

// export { database };



// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDS7m1kItxD5MfOB4vKcS-75FRaDN_qcMo",
  authDomain: "test-76df5.firebaseapp.com",
  databaseURL: "https://test-76df5-default-rtdb.firebaseio.com/",
  projectId: "test-76df5",
  storageBucket: "test-76df5.appspot.com",
  messagingSenderId: "497868115872",
  appId: "1:497868115872:web:464084aa3d8db03fa3dec5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);

export { database, auth };

