import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import FrontIcon from "@mui/icons-material/ArrowCircleRight";
import LeftIcon from "@mui/icons-material/ArrowCircleLeft";
import Home from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import CreditIcon from "@mui/icons-material/PriceCheck";
import DebitIcon from "@mui/icons-material/MoneyOff";
import AddIcon from "@mui/icons-material/GroupAdd";
import { IconButton, Snackbar, Button, Skeleton, Avatar } from "@mui/material";

import { useUser } from "../UserContext";
import Clients from "./Clients";
import Credit from "./Credit";
import Debit from "./Debit";

export default function Admin() {
  const { user: userDetails } = useUser();
  const history = useHistory();

  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isClientsVisible, setIsClientsVisible] = useState(false);
  const [isCreditVisible, setIsCreditVisible] = useState(false);
  const [isDebitVisible, setIsDebitVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const openSnackbar = (message) => {
    setSnackbarKey((prevKey) => prevKey + 1);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const action = (
    <Button
      sx={{ color: "#fff" }}
      size="small"
      onClick={() => setSnackbarOpen(false)}
    >
      <i className="bi bi-x-lg"></i>
    </Button>
  );

  if (!userDetails) {
    history.push(`/login`);
  }

  useEffect(() => {
    if (!userDetails) {
      history.push("/login");
    } else if (userDetails && userDetails.role === "admin") {
      history.push("/admin");
    } else {
      history.push("/dashboard");
    }

    // Simulate loading delay
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }, [history, userDetails]);

  const handleClientsClose = () => {
    setIsClientsVisible(false);
  };
  const handleCreditClose = () => {
    setIsCreditVisible(false);
  };
  const handleDebitClose = () => {
    setIsDebitVisible(false);
  };

  return (
    <div className="mt-5 " style={{ width: "100%", maxWidth: "800px", margin: "auto" }}>
      <br />
      <br />

      <div >
        <div className="py-3 d-flex">
          <IconButton onClick={() => history.goForward()}>
            <LeftIcon sx={{ width: 35, height: 35 }} />
          </IconButton>

          <IconButton onClick={() => history.goBack()}>
            <FrontIcon sx={{ width: 35, height: 35 }} />
          </IconButton>

          <IconButton onClick={() => history.push("admin")}>
            <Home sx={{ width: 35, height: 35 }} />
          </IconButton>
        </div>

        <div className="m-auto">
          <div className="row g-3 px-3">
            {/* All Clients */}
            <div className="col-12 col-md-6">
              {isLoading ? (
                <Skeleton variant="rectangular" className="rounded" height={120}/>
              ) : (
                <div
                  onClick={() => setIsClientsVisible(true)}
                  role="button"
                  className="border p-4 rounded shadow-sm text-center"
                >
                  <PeopleIcon
                    className="bg-primary-subtle rounded-5 text-primary p-2 mb-2"
                    sx={{
                      display: "block",
                      margin: "auto",
                      width: 40,
                      height: 40,
                    }}
                  />
                  All Clients
                </div>
              )}
              {isClientsVisible && <Clients onClose={handleClientsClose} />}
            </div>

            {/* Credit Client */}
            <div className="col-12 col-md-6">
              {isLoading ? (
                <Skeleton variant="rectangular" className="rounded" height={120} />
              ) : (
                <div
                  onClick={() => setIsCreditVisible(true)}
                  role="button"
                  className="border p-4 rounded shadow-sm text-center"
                >
                  <CreditIcon
                    className="bg-success-subtle rounded-5 text-success p-2 mb-2"
                    sx={{
                      display: "block",
                      margin: "auto",
                      width: 40,
                      height: 40,
                    }}
                  />
                  Credit Client
                </div>
              )}
              {isCreditVisible && <Credit onClose={handleCreditClose} />}
            </div>

            {/* Debit Client */}
            <div className="col-12 col-md-6">
              {isLoading ? (
                <Skeleton variant="rectangular" className="rounded" height={120} />
              ) : (
                <div
                  onClick={() => setIsDebitVisible(true)}
                  role="button"
                  className="border p-4 rounded shadow-sm text-center"
                >
                  <DebitIcon
                    className="bg-danger-subtle rounded-5 text-danger p-2 mb-2"
                    sx={{
                      display: "block",
                      margin: "auto",
                      width: 40,
                      height: 40,
                    }}
                  />
                  Debit Client
                </div>
              )}
              {isDebitVisible && <Debit onClose={handleDebitClose} />}
            </div>

            {/* Register New */}
            <div className="col-12 col-md-6">
              {isLoading ? (
                <Skeleton variant="rectangular" className="rounded" height={120} />
              ) : (
                <div
                  onClick={() => history.push("/form")}
                  role="button"
                  className="border p-4 rounded shadow-sm text-center"
                >
                  <AddIcon
                    className="bg-info-subtle rounded-5 text-body p-2 mb-2"
                    sx={{
                      display: "block",
                      margin: "auto",
                      width: 40,
                      height: 40,
                    }}
                  />
                  Register New
                </div>
              )}
            </div>
          </div>
        </div>

        <Snackbar
          key={snackbarKey}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          action={action}
        />
      </div>
    </div>
  );
}
