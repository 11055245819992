import React, { useEffect, useState, useCallback } from "react";
import { ref, onValue, push, remove, get, update } from "firebase/database";
import { database } from "./firebaseConfig";
import { Avatar, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Back from '@mui/icons-material/ArrowBack';
import notificationSound from '../images/mixkit-bell-notification-933.wav';
import { useUser } from "../components/UserContext";

const Admin = () => {
  const [messages, setMessages] = useState([]);
  const [activeClients, setActiveClients] = useState([]);
  const [currentClientId, setCurrentClientId] = useState(null);
  const [lastMessageId, setLastMessageId] = useState(null);
  const [notifiedMessageIds, setNotifiedMessageIds] = useState([]); // Change to an array

  const { replySignal, replyMessage, setReplyMessage } = useUser();

  useEffect(() => {
    if (replySignal) {
      sendReplyToClient();
    }
  }, [replySignal]);

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  // Function to play the notification sound
  const playNotificationSound = useCallback(() => {
    const audio = new Audio(notificationSound);
    audio.play();
  }, []);


  



  useEffect(() => {
    const chatsRef = ref(database, "chats");
    const unsubscribe = onValue(chatsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const allClients = Object.keys(data);
        allClients.forEach((clientId) => {
          const clientData = data[clientId];
          const messagesArray = clientData?.messages
            ? Object.keys(clientData.messages).map((key) => ({
                id: key,
                ...clientData.messages[key],
              }))
            : [];

          const latestMessage = messagesArray[messagesArray.length - 1];
          const unreadCount = messagesArray.filter(
            (msg) => !msg.isRead && msg.sender === "client" // Only count messages from the client
          ).length;

          // Check if the latest message is from the client and is unread
          if (
            latestMessage &&
            latestMessage.sender === "client" && // Ensure it's from client
            !latestMessage.isRead && // Ensure it's unread
            !notifiedMessageIds.includes(latestMessage.id) // Ensure notification hasn't been sent already
          ) {
            playNotificationSound(); // Play sound
            setNotifiedMessageIds((prevIds) => [...prevIds, latestMessage.id]); // Track the notified message
            setLastMessageId(latestMessage.id); // Update the last message ID
          }

          setActiveClients((prevClients) => {
            const clientExists = prevClients.find(
              (client) => client.clientId === clientId
            );
            if (clientExists) {
              return prevClients.map((client) =>
                client.clientId === clientId
                  ? { ...client, unreadMessages: unreadCount }
                  : client
              );
            } else {
              return [
                ...prevClients,
                {
                  clientId,
                  name: clientData?.name || "No Name",
                  email: clientData?.email || "No Email",
                  unreadMessages: unreadCount,
                },
              ];
            }
          });
        });
      }
    });
    return () => unsubscribe();
  }, [lastMessageId, playNotificationSound, notifiedMessageIds]);












  useEffect(() => {
    if (currentClientId) {
      const messagesRef = ref(database, `chats/${currentClientId}/messages`);
      const unsubscribe = onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const messagesArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));

          // Merge previous messages with new ones
          setMessages((prevMessages) => {
            const newMessagesMap = new Map(
              prevMessages.map((msg) => [msg.id, msg])
            );

            messagesArray.forEach((newMessage) => {
              newMessagesMap.set(newMessage.id, newMessage);
            });

            return Array.from(newMessagesMap.values());
          });

          // Mark messages as read when entering the chat
          markMessagesAsRead();
        } else {
          setMessages([]);
        }
      });

      return () => unsubscribe();
    }
  }, [currentClientId]);

  const markMessagesAsRead = async () => {
    if (currentClientId) {
      const messagesRef = ref(database, `chats/${currentClientId}/messages`);
      const snapshot = await get(messagesRef);
      const messages = snapshot.val();

      if (messages) {
        Object.keys(messages).forEach((msgId) => {
          if (!messages[msgId].isRead) {
            const messageRef = ref(
              database,
              `chats/${currentClientId}/messages/${msgId}`
            );
            update(messageRef, { isRead: true });
          }
        });
      }
    }
  };

  const sendReplyToClient = async () => {
    if (replyMessage.trim() && currentClientId) {
      const messagesRef = ref(database, `chats/${currentClientId}/messages`);
      await push(messagesRef, {
        text: replyMessage,
        sender: "admin",
        timestamp: Date.now(),
        isRead: true, // Mark sent messages as read
      });
      setReplyMessage("");
    }
  };

  const deleteClient = async (clientId) => {
    const confirm = window.confirm("Do you want to delete this chat?");
    if (confirm) {
      const clientRef = ref(database, `chats/${clientId}`);

      // Send notification to client
      const notificationMessage = {
        text: "This chat session has been ended. Chats will go away in less than an hour",
        sender: "admin",
        timestamp: Date.now(),
      };

      const messagesRef = ref(database, `chats/${clientId}/messages`);
      await push(messagesRef, notificationMessage);
      await remove(clientRef);

      setActiveClients((prevClients) =>
        prevClients.filter((client) => client.clientId !== clientId)
      );

      if (clientId === currentClientId) {
        setCurrentClientId(null);
      }
    }
  };

  return (
    <div className="chat">
      <div className="">
        {!currentClientId && (
          <div className="">
            <h5>Active Clients</h5>
            {activeClients.length > 0 ? (
              <div role="button">
                {activeClients.map((client) => (
                  <div
                    className="border shadow-sm rounded my-3"
                    key={client.clientId}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      alignItems: "center",
                    }}
                    onClick={() => setCurrentClientId(client.clientId)}
                  >
                    <div>
                      <strong>ID:</strong> {client.clientId} <br />
                      <strong>Name:</strong> {client.name} <br />
                      <strong>Email:</strong> {client.email} <br />
                      {client.unreadMessages > 0 && (
                        <strong style={{ color: "red" }}>
                          Unread Messages: {client.unreadMessages}
                        </strong>
                      )}
                    </div>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteClient(client.clientId);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-black-70">
                <h6>No client is chatting</h6>
                <p>All client chats will appear here</p>
              </div>
            )}
          </div>
        )}
      </div>

      {currentClientId && (
        <div className="chatBody">
          <div className="">
            {messages.map((msg) => (
              <div key={msg.id}>
                <div
                  className={
                    msg.sender === "client"
                      ? "clientMsgInAdmin"
                      : "adminMsgInAdmin"
                  }
                >
                  <div className="chatItem">
                    {msg.text}
                    <div className={msg.sender === "client" && "clientImg"}>
                      {msg.sender === "client" && (
                        <Avatar
                          sx={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "10px 10px 0 10px",
                          }}
                        ></Avatar>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="backToList">
            <IconButton
              className="button"
              onClick={() => setCurrentClientId(null)}
            >
              <Back />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;






















