import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Modal } from "react-bootstrap";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";

import { useUser } from "../components/UserContext";

function Settings() {
  const location = useLocation();
  const client = location.state && location.state.client;

  const { user: userDetails, setUserDetails } = useUser();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [firstName, setFirstName] = useState(userDetails?.firstName || "");
  const [lastName, setLastName] = useState(userDetails?.lastName || "");
  const [email, setEmail] = useState(userDetails?.email || "");
  const [imageFileName, setImageFileName] = useState(
    userDetails?.imageFileName || ""
  );
  const id = userDetails && userDetails.id;
  const role = userDetails && userDetails.role;
  const date = userDetails && userDetails.date;

  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const history = useHistory();

  const [saving, setSaving] = useState(false);
  const [uploadingPicture, setUploadingPicture] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showProfilePicModal, setShowProfilePicModal] = useState(false);
  const setModal =
    new URLSearchParams(location.search).get("setModal") === "true";

  const [backgroundGradient, setBackgroundGradient] = useState("");

  const [fileEmpty, setFileEmpty] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (client || userDetails) {
      setFirstName((client && client.firstName) || userDetails.firstName || "");
      setLastName((client && client.lastName) || userDetails.lastName || "");
      setEmail((client && client.email) || userDetails.email || "");
    }
  }, [client, userDetails]);

  const openProfilePicModal = () => {
    setShowProfilePicModal(true);
  };

  const closeProfilePicModal = () => {
    setShowProfilePicModal(false);
    // history.push(`/settings?`);
  };

  const closeProfilePicModalFromDashboard = () => {
    setShowProfilePicModal(false);
    history.push(`/dashboard?`);
  };

  //upload profile pics

  const handleImageUpload = async (e) => {
    if (!selectedFile) {
      setFileEmpty(true);
      return;
    } else {
      setUploadingPicture(true);
    }

    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      setUploadingPicture(true);
      formData.append("imageFileName", imageFileName);

      const response = await fetch(
        `${apiUrl}/uploadProfilePic?clientId=${userDetails.id}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        // Fetch the updated image path immediately after a successful upload
        setTimeout(() => {
          axios
            .get(`${apiUrl}/getImageName?clientId=${userDetails.id}`)
            .then((response) => {
              setImageFileName(response.data.imageName);
              // Update user details in context
              setUserDetails({
                ...userDetails,
                imageFileName: response.data.imageName,
              });

              // Update localStorage
              localStorage.setItem(
                "userInfo",
                JSON.stringify({
                  ...userInfo,
                  imageFileName: response.data.imageName,
                })
              );
            })
            .catch((error) => {
              openSnackbar("Error fetching profile picture:", error);
              setUploadingPicture(false);
            });
          openSnackbar("Profile Picture Upload successful");
          setFileEmpty(false);
          setSelectedFile(null);
          setUploadingPicture(false);
        }, 5000);
      } else {
        openSnackbar("Profile Picture upload failed");
        setUploadingPicture(false);
      }
    } catch (error) {
      openSnackbar("Error:", error);
      setUploadingPicture(false);
    }
  };

  const handleDeleteImage = async () => {
    try {
      const response = await axios.delete(
        `${apiUrl}/deleteImage?clientId=${userDetails.id}`
      );
      if (response.data.success) {
        axios
          .get(`${apiUrl}/getImageName?clientId=${userDetails.id}`)
          .then((response) => {
            setImageFileName(response.data.imageName);
            // Update user details in context
            setUserDetails({
              ...userDetails,
              imageFileName: response.data.imageName,
            });

            // Update localStorage
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                ...userInfo,
                imageFileName: response.data.imageName,
              })
            );
          })
          .catch((error) => {
            openSnackbar("Error fetching profile picture:", error);
            setUploadingPicture(false);
          });
        console.log("Image deleted successfully");
        openSnackbar("Image deleted successfully");
      } else {
        console.error("Failed to delete image");
        openSnackbar("Failed to delete image");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
      openSnackbar("Error deleting image:", error);
    }
  };

  //fetch image
  useEffect(() => {
    // Fetch image path from the backend whenever userId changes
    axios
      .get(`${apiUrl}/getImageName?clientId=${id}`)
      .then((response) => {
        setImageFileName(response.data.imageName);
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  }, [apiUrl, imageFileName, id]);

  // Generate a random gradient background
  useEffect(() => {
    const randomGradient = getRandomGradient();
    setBackgroundGradient(randomGradient);
  }, []);

  // Function to generate a random gradient
  const getRandomGradient = () => {
    const gradientColors = [
      "linear-gradient(to right, #FF5733, #6A0572)",
      "linear-gradient(to right, #11998e, #38ef7d)",
      "linear-gradient(to right, #e52d27, #b31217)",
      // Add more gradient definitions here
    ];
    const randomIndex = Math.floor(Math.random() * gradientColors.length);
    return gradientColors[randomIndex];
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .min(3, "New password must be at least 3 characters"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPassword")], "Passwords do not match"),
  });

  const handleUpdateUserInfo = async (e) => {
    setSaving(true);
    e.preventDefault();

    try {
      await new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            await axios.put(`${apiUrl}/updateUserInfo`, {
              id: (client && client.id) || userInfo.id,
              firstName,
              lastName,
              email,
            });

            
            openSnackbar("User information updated successfully");
            resolve();
          } catch (error) {
            openSnackbar("An error occurred while updating user information");
            reject(error);
          }
        }, 2000);
      });
    } catch (error) {
      openSnackbar("An error occurred while updating user information");
    } finally {
      setSaving(false);
    }
  };

  const handlePasswordChange = async (
    values,
    { setSubmitting, setErrors, setStatus }
  ) => {
    try {
      // Introduce a 2-second delay using the "await" keyword
      await new Promise((resolve) => setTimeout(resolve, 2000));

      try {
        const response = await axios.put(`${apiUrl}/updatePassword`, {
          id: (client && client.id) || userDetails.id,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        });

        if (response.status === 200) {
          setStatus({ successMessage: "Password updated successfully" });
          openSnackbar("Password updated successfully");
        }
      } catch (error) {
        if (error.response) {
          setErrors({ oldPassword: "Old password is incorrect" });
        } else {
          openSnackbar("An error occurred while updating the password");
        }
      }
    } catch (error) {
      // Handle any errors related to the delay here
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteAccount = async () => {
    setDeleting(true);
    setTimeout(async () => {
      try {
        await axios.delete(`${apiUrl}/deleteUser`, {
          data: { id: (client && client.id) || userDetails.id },
        });

        window.location.href = "/Login";
        localStorage.removeItem("userInfo");
        // openSnackbar("User account deleted successfully");
      } catch (error) {
        openSnackbar("An error occurred while deleting the account");
        setDeleting(false);
      }
    }, 5000);
  };

  const dateDisplay = (dateString) => {
    const displayFormat = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      displayFormat
    );
    return formattedDate;
  };

  const openSnackbar = (message) => {
    setSnackbarKey((prevKey) => prevKey + 1);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const action = (
    <Button
      sx={{ color: "#fff" }}
      size="small"
      onClick={() => setSnackbarOpen(false)}
    >
      <i className="bi bi-x-lg"></i>
    </Button>
  );

  if (!userDetails) {
    history.push("/login");
  }

  return (
    <div>
      <br /><br /><br />
      <div className={`${userDetails?.role === 'admin' && 'mt-5'}`}>
        <div className="p-4">
          <div className="profile p-3 mb-4 rounded-4 col-md-6 m-auto">
            
            <div
              className="rounded-4 background"
              style={{ background: backgroundGradient, height: "120px" }}
            >
              <div className="profile-picture-placeholder-wrapper">
                <Tooltip title="Click to Change Image">
                  {client ? (
                    <div>
                      {client.imageFileName ? (
                        <Avatar
                          src={`${apiUrl}/images/${
                            client && client.imageFileName
                          }`}
                          alt="profile preview"
                          className="avater"
                          onClick={openProfilePicModal}
                        ></Avatar>
                      ) : (
                        <Avatar
                          className="initials avater text-uppercase"
                          onClick={openProfilePicModal}
                        >
                          <h1>
                            {(client &&
                              client.firstName.charAt(0).toUpperCase()) ||
                              firstName.charAt(0).toUpperCase()}
                            {(client &&
                              client.lastName.charAt(0).toUpperCase()) ||
                              lastName.charAt(0).toUpperCase()}
                          </h1>
                        </Avatar>
                      )}
                    </div>
                  ) : (
                    <div>
                      {imageFileName ? (
                        <Avatar
                          src={`${apiUrl}/images/${imageFileName}`}
                          alt="profile preview"
                          className="avater"
                          onClick={openProfilePicModal}
                        ></Avatar>
                      ) : (
                        <Avatar
                          className="initials avater text-uppercase"
                          onClick={openProfilePicModal}
                        >
                          <h1>
                            {(client &&
                              client.firstName.charAt(0).toUpperCase()) ||
                              firstName.charAt(0).toUpperCase()}
                            {(client &&
                              client.lastName.charAt(0).toUpperCase()) ||
                              lastName.charAt(0).toUpperCase()}
                          </h1>
                        </Avatar>
                      )}
                    </div>
                  )}
                </Tooltip>
              </div>
            </div>

            {/* profile picture change */}

            <Modal
              animation={true}
              backdrop="static"
              show={showProfilePicModal || setModal}
              centered
            >
              <div className="">
                <div className="modal-content">
                  <Modal.Header className="bg-light shadow-sm border-0">
                    <Modal.Title className="pt-3 text-muted">
                      <div className="change-profile-picture-header">
                        {client ? (
                          <div className="ms-2">
                            {client.imageFileName ? (
                              <div className="d-flex">
                                <Avatar
                                  variant="rounded"
                                  src={`${apiUrl}/images/${client.imageFileName}`}
                                  sx={{ width: "40px", height: "40px" }}
                                ></Avatar>
                                <span className="m-auto ms-2 text-capitalize">
                                  {client.firstName.charAt(0).toUpperCase() +
                                    client.firstName.slice(1)}
                                </span>
                              </div>
                            ) : (
                              <div className="d-flex ms-2">
                                <Avatar
                                  className="rounded-2 bg-danger"
                                  sx={{
                                    width: 40,
                                    height: 40,
                                  }}
                                ></Avatar>
                                <span className="ms-2 text-capitalize">
                                  {" "}
                                  {firstName}
                                </span>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="ms-2">
                            {imageFileName ? (
                              <div className="d-flex">
                                <Avatar
                                  src={`${apiUrl}/images/${imageFileName}`}
                                  sx={{ width: "40px", height: "40px" }}
                                  variant="rounded"
                                ></Avatar>
                                <span className="m-auto ms-2 text-capitalize">
                                  {firstName.charAt(0).toUpperCase() +
                                    firstName.slice(1)}
                                </span>
                              </div>
                            ) : (
                              <div className="d-flex ms-2">
                                <Avatar
                                  className="rounded-2 bg-danger"
                                  sx={{
                                    width: 40,
                                    height: 40,
                                  }}
                                ></Avatar>{" "}
                                <span className="ms-2 text-capitalize">
                                  {" "}
                                  {firstName}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="p-4">
                    <div className="">
                      <form>
                        <input
                          className="form-control"
                          type="file"
                          accept="image/*"
                          required
                          onChange={(e) => {
                            setSelectedFile(e.target.files[0]);
                            setFileEmpty(false);
                          }}
                        />
                        {fileEmpty && (
                          <p className="text-danger">Please select an image</p>
                        )}
                        <br />
                        <Button
                          type="button"
                          className="btn bg-secondary  text-light shadow-lg"
                          onClick={handleImageUpload}
                        >
                          Upload Image
                        </Button>
                      </form>
                    </div>

                    <div className="change-profile-picture-header">
                      {client ? (
                        <div>
                          {client.imageFileName ? (
                            <div className="d-flex">
                              <Avatar
                                src={`${apiUrl}/images/${client.imageFileName}`}
                                sx={{
                                  width: "80px",
                                  height: "80px",
                                  marginTop: "20px",
                                }}
                                variant="rounded"
                              ></Avatar>
                            </div>
                          ) : (
                            <div className="mt-3">
                              <h4>No Image Uploaded</h4>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {imageFileName ? (
                            <div className="d-flex">
                              {uploadingPicture ? (
                                <div className="mt-3 d-flex">
                                  <span>Uploading...</span>
                                  <span className="Toastify__spinner mt-1  ms-2"></span>
                                </div>
                              ) : (
                                <Avatar
                                  src={`${apiUrl}/images/${imageFileName}`}
                                  sx={{
                                    width: "80px",
                                    height: "80px",
                                    marginTop: "20px",
                                  }}
                                  variant="rounded"
                                ></Avatar>
                              )}
                              <div className="mt-auto mb-auto">
                                {!uploadingPicture && (
                                  <Button
                                    onClick={handleDeleteImage}
                                    className="ms-3"
                                    variant="outlined"
                                    color="error"
                                  >
                                    delete image
                                  </Button>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="mt-3">
                              {!uploadingPicture && <h6>No Image Uploaded</h6>}
                            </div>
                          )}
                        </div>
                      )}

                      {imageFileName === "" && uploadingPicture && (
                        <div className="mt-3 d-flex">
                          <span>Uploading...</span>
                          <span className="Toastify__spinner mt-1  ms-2"></span>
                        </div>
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="bg-light">
                    {!setModal && (
                      <Button
                        variant="contained"
                        onClick={() => {
                          closeProfilePicModal();
                          setFileEmpty(false);
                        }}
                      >
                        Close
                      </Button>
                    )}
                    {setModal && (
                      <div className="pb-3">
                        <Button
                          variant="contained"
                          style={{ fontSize: "10px" }}
                          onClick={() => history.push("/settings")}
                        >
                          stay in settings
                        </Button>

                        <Button
                          variant=""
                          color="success"
                          style={{ fontSize: "10px" }}
                          onClick={closeProfilePicModalFromDashboard}
                        >
                          move to dashboard
                        </Button>
                      </div>
                    )}
                  </Modal.Footer>
                </div>
              </div>
            </Modal>

            <div className="text-center mt-4 p-4 pb-4 rounded-3">
              <h4 className="fw-bold mt-3 text-capitalize">
                {(firstName) ||
                  firstName.charAt(0).toUpperCase() + firstName.slice(1)}{" "}
                {""}
                {(lastName) ||
                  lastName.charAt(0).toUpperCase() + lastName.slice(1)}
              </h4>
              <a
                className="text-body"
                href={`mailTo:${(email) || email}`}
              >
                {(email) || email}
              </a>
              <p className="m-0">
                Account type:{" "}
                <Tooltip
                  title={
                    client && client.role === "client"
                      ? "client account"
                      : role === "admin" && !client
                      ? "admin account"
                      : "client account"
                  }
                >
                  <span className="text-capitalize text-decoration-underline">
                    {(client && client.role) || role}
                  </span>
                </Tooltip>
              </p>
              <p>
                Joined on:{" "}
                {(client && dateDisplay(client.date)) || dateDisplay(date)}
              </p>
            </div>
          </div>

          <form onSubmit={handleUpdateUserInfo}>
            <div className="profile p-4 mb-4 rounded-4 col-md-6 m-auto">
              <h5 className="my-3">Account Settings</h5>
              <p>Change your account information</p>

              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  readOnly={userDetails.role === 'client'}
                />
                <label htmlFor="firstName">First Name</label>
              </div>

              <div className="form-floating">
                <input
                  type="text"
                  className="form-control mt-3"
                  id="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  readOnly={userDetails.role === 'client'}
                />
                <label htmlFor="lastName">Last Name</label>
              </div>

              <div className="form-floating">
                <input
                  type="email"
                  className="form-control mt-3"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  readOnly={userDetails.role === 'client'}
                />
                <label htmlFor="email">Email</label>
              </div>

              <div className="mt-5 mb-3">
                {!saving ? (
                  <button
                    type="submit"
                    className="btn bg-success-subtle text-success rounded-5 w-100"
                  >
                    Save Changes
                  </button>
                ) : (
                  <>
                    <button className="btn bg-success-subtle text-success rounded-5 w-100">
                      Saving changes...
                      <span className="spinner-border spinner-border-sm ms-3"></span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </form>

          <div className="profile p-4 mb-4 rounded-4 col-md-6 m-auto">
            <h5 className="my-3">Password Settings</h5>
            <p>Change your account password</p>
            <Formik
              initialValues={{
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handlePasswordChange}
            >
              {({ isSubmitting, status }) => (
                <Form >
                  <div className="form-floating my-3">
                    <Field
                      className="form-control"
                      type="password"
                      name="oldPassword"
                      placeholder="Old Password"
                      disabled={userDetails.role === 'client'}
                    />
                    <ErrorMessage
                      name="oldPassword"
                      component="div"
                      className="error text-danger"
                    />
                    <label htmlFor="firstName">Old Password</label>
                  </div>

                  <div className="form-floating mb-3">
                    <Field
                      className="form-control"
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                      disabled={userDetails.role === 'client'}
                    />
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="error  text-danger"
                    />
                    <label htmlFor="firstName">New Password</label>
                  </div>

                  <div className="form-floating mb-3">
                    <Field
                      className="form-control"
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      disabled={userDetails.role === 'client'}
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error  text-danger"
                    />
                    <label htmlFor="firstName">Confirm Password</label>
                  </div>

                  <div className="mt-5 mb-2">
                    {!isSubmitting ? (
                      <button
                        className="btn bg-success-subtle text-success mb-3 rounded-5 w-100"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Update Password
                      </button>
                    ) : (
                      <button
                        className="btn bg-success-subtle text-success mb-3 rounded-5 w-100"
                        type="submit"
                      >
                        Updating Password . . .
                        <span className="spinner-border spinner-border-sm ms-3"></span>
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="profile p-4 pb-4 rounded-4 col-md-6 m-auto" hidden={userDetails.role === 'client'}>
            <h5 className="my-3">Delete Account</h5>
            <p className="text-danger">Completely erase your account</p>
            {!deleting ? (
              <button
                onClick={() => {
                  setShowDeleteModal(true);
                }}
                className="btn bg-danger-subtle text-danger mb-3 rounded-5 w-100"
              >
                Delete Account
              </button>
            ) : (
              <button className="btn bg-danger-subtle text-danger mb-3 rounded-5 w-100">
                Deleting Account{" "}
                <span className="spinner-border spinner-border-sm ms-2"></span>
              </button>
            )}
          </div>
       
        </div>

        <div className="modal-container">
          <Modal
            className="bottom-slide"
            animation={true}
            backdrop="static"
            show={showDeleteModal}
          >
            <div
              className="line"
              onClick={() => {
                setShowDeleteModal(false);
              }}
            ></div>
            <Modal.Header className="bg-light border-1">
              <Modal.Title className="pt-3 text-danger">
                <i className="bi bi-exclamation-circle me-2"></i> Confirm
                Deletion
              </Modal.Title>
            </Modal.Header>

            {userDetails && !client && (
              <Modal.Body className="p-4">
                Are you sure you want to delete your account? This action cannot
                be undone.
              </Modal.Body>
            )}
            {client && (
              <Modal.Body className="p-4">
                Are you sure you want to delete{" "}
                <b className="text-uppercase">
                  {client.firstName + " " + client.lastName}'s{" "}
                </b>{" "}
                account? This action cannot be undone and they wont be able to
                log into their account again
              </Modal.Body>
            )}

            <Modal.Footer className="bg-white border-0 p-0">
              <div className="p-4">
                {deleting ? (
                  <div className="w-100">
                    <Button className="w-100" variant="contained" color="error">
                      deleting...{" "}
                      <span className="Toastify__spinner ms-2"></span>
                    </Button>
                  </div>
                ) : (
                  <div className="m-2">
                    <Button
                      variant="outlined"
                      className="me-3"
                      onClick={() => {
                        setShowDeleteModal(false);
                      }}
                    >
                      cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        handleDeleteAccount();
                      }}
                    >
                      delete
                    </Button>
                  </div>
                )}
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      {/* Alert box */}
      <Snackbar
        key={snackbarKey}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={action}
      />

    </div>
  );
}

export default Settings;
