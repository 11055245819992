import React, { useEffect, createContext, useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getDatabase, ref, onValue } from "firebase/database";
import { database } from './firebaseConfig'; // Import your database configuration
// import notificationSound from '../images/mixkit-bell-notification-933.wav';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationOpened, setNotificationOpened] = useState(false);
  const [isContactOffcanvasOpen, setIsContactOffcanvasOpen] = useState(false);
  const [lastVisitedPage, setLastVisitedPage] = useState(() => {
    const storedPage = localStorage.getItem("lastPage");
    return storedPage ? [storedPage] : [];
  });

  const history = useHistory();
  // const location = useLocation();

  const loginUser = (userData) => {
    setUser(userData);
    localStorage.setItem("userInfo", JSON.stringify(userData));
  };
  
  const logoutUser = () => {
    setUser(null);
    localStorage.removeItem("userInfo");
  };

  const setUserDetails = (details) => {
    // Use the functional update pattern to ensure consistency
    setUser((prevUser) => ({ ...prevUser, ...details }));
  };

  const updateBalance = (newBalance) => {
    setLoadingBalance(true);
    setUser((prevUser) => {
        if (!prevUser) return null;
        const updatedUser = { ...prevUser, balance: newBalance };
        localStorage.setItem("userInfo", JSON.stringify(updatedUser));
        return updatedUser;
      });
      setTimeout(() => {
        setLoadingBalance(false);
    }, 2000);
};



  const addNotification = (message, type = "info") => {
    const newNotification = { id: Date.now(), message, type };
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      newNotification,
    ]);
  };

  useEffect(() => {
    const storedUserInfo = localStorage.getItem("userInfo");
    if (storedUserInfo) {
      setUser(JSON.parse(storedUserInfo));
    }
  }, []);

  useEffect(() => {
    
  },[])




  const [replySignal, setReplySignal] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const clientId = localStorage.getItem("clientId") || `client_${Date.now()}`;
  const database = getDatabase();
  


  const [messageSignal, setmessageSignal] = useState(false);
  const [message, setMessage] = useState("");

  const handleSendMessage = (data) => {
    // Set the reply signal to true to notify AdminChat that a reply should be sent
    setmessageSignal(true);
    setMessage(data);
    // Optionally, after sending the reply, reset the signal
    setTimeout(() => {
      setmessageSignal(false);
    }, 500);
  };
  
  const handleSendReply = (data) => {
    // Set the reply signal to true to notify AdminChat that a reply should be sent
    setReplySignal(true);
    setReplyMessage(data);
    // Optionally, after sending the reply, reset the signal
    setTimeout(() => {
      setReplySignal(false);
    }, 500);
  };

  const fetchNewMessages = async (clientId) => {
    const messagesRef = ref(database, `chats/${clientId}/messages`);

    return new Promise((resolve, reject) => {
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const fetchedMessages = Object.values(data); // Assuming messages are stored as an object
          setMessages(fetchedMessages);
          resolve(fetchedMessages);
        } else {
          resolve([]); // Return an empty array if no messages
        }
      }, (error) => {
        reject(error); // Handle any errors
      });
    });
  };

  const playNotificationSound = () => {
    const audio = new Audio('../images/mixkit-bell-notification-933.wav'); 
    audio.play();
  };

  




  return (
    <UserContext.Provider
      value={{
        user,
        loadingBalance,
        updateBalance,
        loginUser,
        logoutUser,
        setUserDetails,
        lastVisitedPage,
        notifications,
        addNotification,
        notificationOpened,
        setNotificationOpened,
        isContactOffcanvasOpen,
        setIsContactOffcanvasOpen,

        handleSendReply, 
        replySignal,
        replyMessage, 
        setReplyMessage,
        
        handleSendMessage,
        message,
        setMessage,
        messageSignal,

        messageModalOpen,
        setMessageModalOpen,
        fetchNewMessages,
        playNotificationSound,
       
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
