/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowRight from "@mui/icons-material/ArrowRight";
import Button from "@mui/material/Button";
import moment from "moment";
import { useUser } from "../UserContext";

const RecentTransactionsTable = () => {
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const location = useLocation();
  const client = location.state && location.state.client;
  const history = useHistory();
  const { user: userDetails } = useUser();

  const userAccount =
    (client && client.account) || (userDetails && userDetails.account);

  const [recentTransaction, setRecentTransaction] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch and group transactions
  useEffect(() => {
    if (userAccount) {
      axios
        .get(`${apiUrl}/history/${userAccount}`)
        .then((response) => {
          const transactions = response.data;
          // console.log(response.data);
          setRecentTransaction(transactions); // Directly set the transactions without grouping
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
          setLoading(false);
        });
    }
  }, [apiUrl, userAccount]);
  

  return (
    <div>
      <div className="mt-5 recent-transactions border shadow-sm rounded-3">
        <div className="p-4">
          <div className="py-3">
            <h5 className="text-muted">Recent Transactions</h5>
          </div>

          {loading ? (
            <p>Loading transactions...</p>
          ) : !userAccount ? (
            <p>No account found.</p>
          ) : recentTransaction.length > 0 ? (
            <TableContainer className="history-table">
              <Table>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell className="table-cell pe-5">S/N</TableCell>
                    <TableCell className="table-cell pe-5">Amount</TableCell>
                    <TableCell className="table-cell pe-5">
                      Transaction Type
                    </TableCell>
                    <TableCell className="table-cell pe-5">Sender</TableCell>
                    <TableCell className="table-cell pe-5">Receiver</TableCell>
                    <TableCell className="table-cell pe-5">
                      Description
                    </TableCell>
                    <TableCell className="table-cell pe-5">Time</TableCell>
                    <TableCell className="table-cell">Status</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
  {recentTransaction.map((transaction, index) => {
    const isSender = transaction.role === "sender";
    const isReceiver = transaction.role === "receiver";

    return (
      <TableRow key={index}>
        {/* Serial Number */}
        <TableCell className="table-body-cell">{index + 1}</TableCell>

        {/* Amount */}
        <TableCell className={transaction.operation === 'credit' ? 'text-success ' : 'text-danger'}>
        
          <span className={transaction.operation === 'credit' ? 'bg-success-subtle text-success px-2 rounded' : "bg-danger-subtle text-danger px-2 rounded"}>
          {transaction.operation === 'credit' ? `+${transaction.amount}` : `-${transaction.amount}`}
          </span>
        </TableCell>

        {/* Transaction Type */}
        <TableCell className="table-body-cell text-capitalize">
          {transaction.transactionType || "wire transfer"}
        </TableCell>

        {/* Sender */}
        <TableCell className="table-body-cell text-capitalize">
          {isReceiver
            ? transaction.relatedParty?.name || transaction.relatedName || "Name withheld"
            : transaction.name}
        </TableCell>

        {/* Receiver */}
        <TableCell className="table-body-cell text-capitalize">
          {isSender
            ? transaction.relatedParty?.name || transaction.relatedName || "Name withheld"
            : transaction.name}
        </TableCell>

        {/* Description */}
        <TableCell className="table-body-cell text-capitalize">
          {transaction.description || ""}
        </TableCell>

        {/* Time */}
        <TableCell className="table-body-cell">
          {transaction.time
            ? moment(transaction.time, "HH:mm:ss").format("h:mm A")
            : "Invalid Time"}
        </TableCell>

        {/* Status */}
        <TableCell className="table-body-cell text-capitalize text-success">
          {transaction.status || "N/A"}
        </TableCell>
      </TableRow>
    );
  })}
</TableBody>


              </Table>
            </TableContainer>
          ) : (
            <p>No transactions yet</p>
          )}

          {recentTransaction.length > 0 && (
            <div className="py-4">
              <Button
                color="primary"
                size="small"
                className="text-capitalize"
                onClick={() =>
                  history.push({
                    pathname: `${
                      client ? `/history/${client.id}` : "/history"
                    }`,
                    state: { client },
                  })
                }
              >
                View All Transactions <ArrowRight />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentTransactionsTable;
