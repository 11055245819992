import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useUser } from "../UserContext";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Logo from '../../images/logo.png';

const Login = () => {
  const { user: userDetails } = useUser();
  const history = useHistory();
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;
  const { loginUser } = useUser();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const lastVisitedPage = localStorage.getItem("lastPage");

  const handleLogin = (values) => {
    setIsLoading(true);
    setError("");
    setSuccess("");
    axios
      .post(`${apiUrl}/login`, values)
      .then(async (response) => {
        if (response.data && response.status === 200) {
          const { role } = response.data;
          setSuccess("Login Successful");
          setError("");

          const userData = {
            id: response.data.id,
            account: response.data.account,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            email: response.data.email,
            phoneNumber: response.data.phoneNumber,
            supportPin: response.data.supportPin,
            gender: response.data.gender,
            country: response.data.country,
            state: response.data.state,
            city: response.data.city,
            street: response.data.street,
            zipcode: response.data.zipcode,
            role: response.data.role,
            date: response.data.date,
            imageFileName: response.data.imageFileName,
            balance: response.data.balance,
            limit: response.data.limit,
            bank: response.data.bank,
            accountType: response.data.accountType,
            swiftCode: response.data.swiftCode,
            bankAddress: response.data.bankAddress,
            routing: response.data.routing,
            status: response.data.status,
          };
          loginUser(userData);

          if (lastVisitedPage) {
            history.push(lastVisitedPage);
          } else {
            if (role === 'admin') {
              history.push('/admin');
            } else {
              history.push('/dashboard');
            }
          }
        }
      })
      .catch((error) => {
        setTimeout(() => {
          if (error) {
            setIsLoading(false);
            setSuccess("");
            setError("Unable to Connect to Database");
          }
          if (error.response.data && error.response.status === 400) {
            setError("Invalid email format");
            setIsLoading(false);
          }
          if (error.response.data && error.response.status === 401) {
            setError("Invalid Login Credentials");
            values.password = "";
            setIsLoading(false);
          }
        }, 0);
      });
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  useEffect(() => {
    const lastVisitedPage = localStorage.getItem('lastVisitedPage');
    if (lastVisitedPage) {
      history.push(lastVisitedPage);
    } else {
      if (!userDetails) {
        history.push('/login');
      } else if (userDetails && userDetails.role === 'admin') {
        history.push('/admin');
      } else {
        history.push('/dashboard');
      }
    }
  }, [history, userDetails]);

  return (
    <div className="account-container">
      <div className="account rounded py-5">
        <img className='d-flex' src={Logo} alt="" width={150} style={{margin: '-40px auto'}}/>
      <h5 className='my-4'>Good to see you again,</h5>

        <div className="account-items">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            <Form>
              {error && (
                <p className="alert border-danger-subtle text-danger">
                  <i className="bi bi-exclamation-octagon me-3"></i>
                  {error}
                </p>
              )}

              {success && (
                <p className="alert alert-success border-success-subtle text-success">
                  <i className="bi bi-check2-circle me-3"></i>
                  {success}
                </p>
              )}

              <div className="mb-3 mt-4 form-floating">
                <Field
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder=" "
                  autoComplete="email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message text-danger"
                />
                <label htmlFor="email">Email</label>
              </div>

              <div className="mb-3 form-floating">
                <Field
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder=" "
                  autoComplete="password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error-message text-danger"
                />
                <label htmlFor="password">Password</label>
              </div>

              {isLoading ? (
                <button
                  type="submit"
                  className="btn blue-btn text-light rounded-1 shadow-sm disabled"
                >
                  Please Wait ...
                  <span
                    className="spinner-border spinner-border-sm border-1 ms-3"
                    role="status"
                  ></span>
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn blue-btn text-light rounded-1 px-4 shadow-sm"
                >
                  Login
                </button>
              )}

              {/* <hr className="text-muted"/> */}

              <div className="bottom-text mt-2">
                <small>
                  Forgot Password?{" "}
                  <Link className="text-muted" to="/contactForm?source=reqLogin">
                    Contact Admin to Reset Password
                  </Link>
                </small>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
