import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  TextField,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Button,
  Skeleton
} from "@mui/material";




import CloseIcon from "@mui/icons-material/Close";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVert from "@mui/icons-material/MoreVert";

import { useUser } from "../UserContext";

import Credit from "./Credit";
import Debit from "./Debit";
import axios from "axios";

const Clients = ({ onClose }) => {
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;
  const { user: userDetails } = useUser();
  const location = useLocation();
  const history = useHistory();
  
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]); // Filtered clients list
  const [searchQuery, setSearchQuery] = useState(""); // Search query
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isCreditVisible, setIsCreditVisible] = useState(false);
  const [isDebitVisible, setIsDebitVisible] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const profilePicture = selectedClient?.imageFileName;


  const handleCloseDialog = () => {
    setOpenDialog(false);
    // setAnchorEl(null);
  };
 

 

  const fetchClients = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/clients`);
      setClients(response.data);
      setFilteredClients(response.data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [apiUrl]);
  

  // Call the fetchClients function when the component mounts or apiUrl changes
  useEffect(() => {
    fetchClients();
  }, [apiUrl, fetchClients]);

  // Filter clients based on search query
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = clients.filter(
      (client) =>
        client.firstName.toLowerCase().includes(query) ||
        client.lastName.toLowerCase().includes(query) ||
        client.email.toLowerCase().includes(query)
    );
    setFilteredClients(filtered);
  };

  const handleMenuOpen = (event, client) => {
    setAnchorEl(event.currentTarget);
    setSelectedClient(client);
    event.stopPropagation();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCreditClose = () => {
    setIsCreditVisible(false);
  };

  const handleDebitClose = () => {
    setIsDebitVisible(false);
  };

  const handleAction = (action) => {
    handleMenuClose();
    if (action === "Credit Client") {
      setIsCreditVisible(true);
    }
    if (action === "Debit Client") {
      setIsDebitVisible(true);
    }
  };

  const handleLock = async (id) => {
    try {
      await axios.put(`${apiUrl}/updateUserStatus/${id}`);
        setSelectedClient((prevState) => {
        if (!prevState) return prevState;
        const updatedStatus = prevState.status === "blocked" ? "active" : "blocked";
        return { ...prevState, status: updatedStatus };
      });
    fetchClients()
    } catch (error) {
      console.error("Error updating user status:", error);
      alert(error.response?.data?.error || "Failed to update user status");
    }
  };



  const handleDeleteWarning = (client) => {
    setSelectedClient(client);
    setOpenDialog(true);
    setAnchorEl(null);
  };


  const handleDelete = async (id) => {
    setLoading(true);
    try {
        await axios.delete(`${apiUrl}/deleteUser`, {
            params: { id },
        });
        setTimeout(() => {
            setClients((prevClients) =>
                prevClients.filter((client) => client.id !== id)
            );
            setFilteredClients((prevClients) =>
                prevClients.filter((client) => client.id !== id)
            );
            setOpenDialog(false); 
        }, 3000);
    } catch (error) {
        console.error("Error deleting client:", error);
        alert(error.response?.data?.error || "Failed to delete client");
    } finally {
        setLoading(false);
    }
};

  
  

  return (
    <Drawer
      anchor="bottom"
      open={true}
      onClose={onClose}
      keepMounted
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          maxWidth: 800,
          maxHeight: "80vh",
          overflowY: "scroll",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          margin: "auto",
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          boxShadow: 3,
        },
      }}
      ModalProps={{
        disableEnforceFocus: true,
      }}
    >
      <div
        className="rounded-top"
        style={{
          padding: 10,
          position: "fixed",
          width: "100%",
          maxWidth: "800px",
          zIndex: 999,
          background: '#fff'
        }}
      >
   
        <div className="d-flex justify-content-between">
          <Typography variant="h6">Clients</Typography>
          <IconButton
            onClick={onClose}
            className="bg-danger-subtle text-danger"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="d-block w-100 py-3">
          <TextField
            label="Search name or email"
            type="text"
            variant="outlined"
            fullWidth
            size="small"
            value={searchQuery}
            onChange={handleSearch}
          />
          {searchQuery && <small>{filteredClients.length} clients found</small>}
        </div>
        
      </div>
      {loading ? 
      <div style={{marginTop: 150 }}>
        <div className="p-4">
          <div className="d-flex justify-content-between mb-3">
            <Skeleton variant="rectangular" className="rounded me-3" sx={{width:'100%', maxWidth:800}} height={70} />
            <Skeleton variant="circular" width={70} height={60} />
          </div>

          <div className="d-flex justify-content-between mb-3">
            <Skeleton variant="rectangular" className="rounded me-3" sx={{width:'100%', maxWidth:800}} height={70} />
            <Skeleton variant="circular" width={70} height={60} />
          </div>

          <div className="d-flex justify-content-between">
            <Skeleton variant="rectangular" className="rounded me-3" sx={{width:'100%', maxWidth:800}} height={70} />
            <Skeleton variant="circular" width={70} height={60} />
          </div>
        </div>

      </div> : (
        <List className="shadow-sm" style={{ marginTop: 150 }}>
        {filteredClients.map((client) => (
          <ListItem
            className="border my-2 rounded"
            key={client.id}
            role="button"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "98%",
              margin: "auto",
              boxShadow: "0 0 3px #0002",
            }}
            onClick={() => history.push("/profile/?id=" + client.id)}
          >
            <div className="px-5">
              <span className="position-absolute" style={{ left: 26, top: 25 }}>
                {client?.status === "blocked" && <LockIcon color="error" />}
              </span>
              <ListItemText
                className="text-capitalize"
                primary={client.firstName + " " + client.lastName}
                secondary={client.email}
              />
            </div>

            <IconButton onClick={(event) => handleMenuOpen(event, client)}>
              <Typography>
                <MoreVert />
              </Typography>
            </IconButton>
          </ListItem>
        ))}
        
        <div className="p-3 text-muted">
            {filteredClients.length } Clients
        </div>

      </List>
      )}
      <Menu 
        anchorEl={anchorEl} 
        open={Boolean(anchorEl)} 
        onClose={handleMenuClose}
        >
        <MenuItem onClick={() => history.push("/profile/?id=" + selectedClient.id)}>
          {profilePicture ? (
            <Avatar
              sx={{
                border: "2px solid #384c70",
                cursor: "pointer",
                width: 30,
                height: 30,
              }}
              src={`${apiUrl}/images/${profilePicture}`}
            ></Avatar>
          ) : (
            <Avatar
              sx={{
                border: "1px solid #384c70",
                cursor: "pointer",
                color: "#fff",
                background: "#0005",
              }}
            />
          )}
          <span className="text-capitalize ps-2">{selectedClient?.firstName}</span>
        </MenuItem>

        <MenuItem onClick={() => handleAction("Credit Client")}>
          <CreditCardIcon sx={{ marginRight: 1 }} />
          Credit Client
        </MenuItem>
        <MenuItem onClick={() => handleAction("Debit Client")}>
          <MoneyOffIcon sx={{ marginRight: 1 }} />
          Debit Client
        </MenuItem>
        <MenuItem
          onClick={() => handleLock(selectedClient.id)}
          className={selectedClient?.status === "blocked" && "text-danger"}
        >
          <LockIcon sx={{ marginRight: 1 }} />
          {selectedClient?.status === "active"
            ? "Lock Account"
            : "Account is locked"}
        </MenuItem>
        <MenuItem onClick={() => handleDeleteWarning(selectedClient)}>
          <DeleteIcon sx={{ marginRight: 1 }} />
          Delete Client
        </MenuItem>
      </Menu>

      



      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        keepMounted
        disableEnforceFocus
        onClose={handleCloseDialog}
        >
         <DialogTitle>
            {selectedClient
                ? `Are you sure you want to delete ${selectedClient.firstName} ${selectedClient.lastName}?`
                : "Are you sure you want to delete this client?"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
            Deleting a client is permanent and cannot be undone. 
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {!loading && <Button variant="contained" size='small' onClick={handleCloseDialog}>Cancel</Button>}
            <Button variant="text" size='small' onClick={() => handleDelete(selectedClient?.id)} color="error" disabled={loading}>
            {loading ? <span>Deleting... {<CircularProgress size={20} /> }</span>: "Delete"}
            </Button>
        </DialogActions>
        </Dialog>




      {isCreditVisible && <Credit onClose={handleCreditClose} selectedClient={selectedClient} />}
      {isDebitVisible && <Debit onClose={handleDebitClose} selectedClient={selectedClient} />}
    </Drawer>
  );
};

export default Clients;
