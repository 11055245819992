import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";

import { useUser } from "./UserContext";
const Noti = () => {
  const {
    user: userDetails,
    setNotificationOpened,
  } = useUser();
  // const firstName = userDetails && userDetails.firstName;
  // const lastName = userDetails && userDetails.lastName;
  const account = userDetails && userDetails.account;

  // const [transactionTypes, setTransactionTypes] = useState([]);
  const [notification, setNotification] = useState(null);
  const history = useHistory();
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const [selectedNotification, setSelectedNotification] = useState(null);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Fetch transaction history from the server
  useEffect(() => {
    axios
      .get(`${apiUrl}/history/${account}`)
      .then((response) => {
        setNotification(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl, account]);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  //OPEN NOTIFICATION DRAWER
  const openDrawer = (selectedNotificationId, selectedNotification) => {
    setSelectedNotification(selectedNotification);
    setSelectedTransactionId(selectedNotificationId);
    console.log("Selected Notification ID:", selectedNotificationId);
    setDrawerOpen(true);
  };

  // MARK NOTIFICATION AS READ
  useEffect(() => {
    if (selectedTransactionId) {
      axios
        .post(`${apiUrl}/mark-notification-read`, {
          clientId: account,
          notificationId: selectedTransactionId,
        })
        .then((response) => {
          axios
            .get(`${apiUrl}/history/${account}`)
            .then((response) => {
              setNotification(response.data);
              openSnackbar("Notification marked as read");
              setNotificationOpened(true);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          openSnackbar("Error marking notification as read");
          console.error("Error marking notification as read:", error);
        });
    }
  }, [selectedTransactionId, apiUrl, account, setNotificationOpened]);

  const openSnackbar = (message) => {
    setSnackbarKey((prevKey) => prevKey + 1);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const action = (
    <Button
      sx={{ color: "#fff" }}
      size="small"
      onClick={() => setSnackbarOpen(false)}
    >
      <i className="bi bi-x-lg"></i>
    </Button>
  );

  if(!userDetails){
    return(
      <div className="text-center shadow-sm p-5">
        <p>You are not loged in</p>
        <Button variant="contained" onClick={() => history.push("/login")}>login</Button>
      </div>
    )
  }

  return (
    <div className="pb-5">
      <br /><br /><br /> <br />
      <div
        className="mb-5 border shadow-sm rounded p-4 pb-5"
        style={{
          width: "95%",
          maxWidth: "800px",
          margin: "auto",
          background: "#fff",
        }}
      >

        <h4 className="mb-4">Notifications</h4>
        {notification && notification.length > 0 ? (
          notification.some((notif) => notif.msgRead === "0") ? (
            notification.map((notifications) => {
              if (
                userDetails &&
                userDetails.account &&
                notifications.msgRead.trim() === "0"
              ) {
                return (
                  <div key={notifications.id}>
                  <div>
                    <MenuItem
                      onClick={() =>
                        openDrawer(notifications.id, notifications)
                      }
                      className="mb-4"
                      style={{
                        cursor: "pointer",
                        background: "#fff",
                        margin: "auto",
                        borderRadius: "0",
                        borderBottom: "1px solid #e4e4e470",
                        // boxShadow: "0 0 10px #e4e4e470"
                      }}
                    >
                      <div className="">
                        <div className="p-0 m-0 d-flex ">
                          <div
                            className={`m-auto ${
                              notifications.type === "credit"
                                ? "bi bi-arrow-up-square-fill text-success"
                                : "bi bi-arrow-down-square-fill text-danger"
                            }`}
                          ></div>
                          <div className="m-0 d-flex ">
                            <div className="d-flex">
                              {notifications.type === "credit" ? (
                                <div className="ms-4">
                                  <h6
                                    className="px-3 rounded bg-success-subtle text-success"
                                    style={{ width: "max-content" }}
                                  >
                                    ${notifications.amount}
                                  </h6>
                                  <p className="m-0 fw-bolder text-light-emphasis">
                                    Credit Alert
                                  </p>
                                  <p className="text-capitalize">
                                    From {notifications.sender} (
                                    {notifications.account})
                                  </p>
                                  <p
                                    style={{
                                      color: "grey",
                                      fontSize: "11px",
                                      marginTop: "-20px",
                                    }}
                                  >
                                    {moment(notifications.date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                </div>
                              ) : (
                                <div className="ms-4">
                                  <h6
                                    className="px-3 rounded bg-danger-subtle text-danger"
                                    style={{ width: "max-content" }}
                                  >
                                    ${notifications.amount}
                                  </h6>
                                  <p className="m-0 fw-bolder text-light-emphasis">
                                    Debit Alert
                                  </p>
                                  <p className="text-capitalize">
                                    to {notifications.receiver} (
                                    {notifications.receiverAcc})
                                  </p>
                                  <p
                                    style={{
                                      color: "grey",
                                      fontSize: "11px",
                                      marginTop: "-20px",
                                    }}
                                  >
                                    {moment(notifications.date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                </div>
                              )}
                              <div>
                                <i className="bi bi-chevron-right position-absolute end-0"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MenuItem>
                  </div>
                  </div>
                  
                );
              }
              return null;
            })
          ) : (
            <div className="text-center">
              <div className="pt-5">
                <i className="bi bi-stack text-muted" style={{fontSize:"40px"}}></i>
                <p>No unread messages</p>
              </div>
            </div>
          )
        ) : (
          userDetails && (
            <div className="text-center">
            <div className="pt-5">
              <i className="bi bi-stack text-muted" style={{fontSize:"40px"}}></i>
              <p>No messages available</p>
            </div>
          </div>
          )
        )}
      </div>

      {/* Notification Details Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <Box
          sx={{ width: 300, padding: 2 }}
          role="presentation"
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
        >
          <Typography variant="h6">Notification Details</Typography>
          {selectedNotification && (
            <div className="my-3">
              <Typography>{`Sender: ${selectedNotification.sender}`}</Typography>
              <hr />
              <Typography className="text-capitalize">{`Receiver: ${selectedNotification.receiver}`}</Typography>
              <hr />
              <Typography>{`Amount: $${selectedNotification.amount}.00`}</Typography>
              <hr />
              <Typography className="text-capitalize">{`Type: ${selectedNotification.type}`}</Typography>
              <hr />
              <Typography>{`Date: ${moment(selectedNotification.date).format("DD MMM YYYY")}`}</Typography>
              <hr />

              <div className="">
                <Button
                  variant="contained"
                  onClick={() =>
                    history.push(`/print-receipt/${selectedTransactionId}`)
                  }
                >
                  show receipt
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Drawer>

      {/* Alert box */}
      <Snackbar
        key={snackbarKey}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={action}
      />
    </div>
  );
};

export default Noti;
