import React, { useEffect } from 'react';

const GoogleTranslate = () => {
  useEffect(() => {
    // Load the Google Translate script dynamically, but only if it hasn't been loaded before
    if (!window.googleTranslateScriptLoaded) {
      const googleTranslateScript = document.createElement('script');
      googleTranslateScript.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      googleTranslateScript.async = true;
      document.body.appendChild(googleTranslateScript);
      window.googleTranslateScriptLoaded = true;
    }

    // Define the initialization function if not already defined
    window.googleTranslateElementInit = window.googleTranslateElementInit || (() => {
      try {
        const savedLanguage = localStorage.getItem('googleTranslateLanguage') || 'en';

        // Initialize Google Translate
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'en',
            autoDisplay: true,
            includedLanguages: '',
            layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
          },
          'google_translate_element'
        );

        // Save selected language to localStorage
        window.google.translate.TranslateElement.prototype.onChange = function (event) {
          const selectedLang = event.language;
          localStorage.setItem('googleTranslateLanguage', selectedLang);
        };

        // Apply saved language, if any
        if (savedLanguage !== 'en') {
          const selectBox = document.querySelector('.goog-te-combo');
          if (selectBox) selectBox.value = savedLanguage;
        }
      } catch (error) {
        console.error("Google Translate Initialization Error:", error);
      }
    });

    // Cleanup function to prevent memory leaks
    return () => {
      const googleTranslateElement = document.getElementById('google_translate_element');
      if (googleTranslateElement) googleTranslateElement.innerHTML = ''; // Clear widget on unmount
    };
  }, []);

  // Hide Google Translate banner and prevent layout disruption
  useEffect(() => {
    const hideTranslateBanner = () => {
      const translateIframe = document.querySelector('iframe.goog-te-banner-frame');
      if (translateIframe) {
        translateIframe.style.display = 'none'; // Hide Google Translate banner
      }

      // Override body positioning styles
      document.body.style.position = 'static';
      document.body.style.top = '0';
    };

    hideTranslateBanner();
    window.addEventListener('resize', hideTranslateBanner);

    return () => {
      window.removeEventListener('resize', hideTranslateBanner);
    };
  }, []);

  return <div id="google_translate_element" />;
};

export default GoogleTranslate;
