import React from "react";
import logo from "../images/logo.png";
import { useUser } from "../components/UserContext";

export default function Footer() {
  // const year = new Date().getFullYear();
  const { user: userDetails} = useUser();
  if(!userDetails){
    return;
  }
  return (
    <footer className="footer">
      <br />
      <br />
      <br />
      <div className="">
        <a href="/" >
          <img src={logo} alt="" />
        </a>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
     
    </footer>
  );
}
