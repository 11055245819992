import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useUser } from "../components/UserContext";
import axios from "axios";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import btc from "../images/btc.png";
import eth from "../images/eth.png";
import usdt from "../images/usdtTrc-20.png";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const WithdrawCrypto = (balance) => {
  const { user: userDetails } = useUser();
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;
  const [processing, setProcessing] = React.useState(false);
  const [transactionSuccess, setTransactionSuccess] = React.useState(false);

  const [cryptoTypeToCredit, setCryptoTypeToCredit] = useState("")
  const [cryptoAddressToCredit, setCryptoAddressToCredit] = useState("")
  const [cryptoAmountToSend, setCryptoAmountToSend] = useState("")
  const [description, setDescription] = useState("")
  const [transactionId, setTransactionId] = useState("");

  const [progress, setProgress] = useState(0);

  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const history = useHistory();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 5
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleWithdrawal = async () => {

    if(!cryptoTypeToCredit.trim() || !cryptoAddressToCredit.trim() || !cryptoAmountToSend.trim()){
      openSnackbar("There is an empty field");
      return;
    }
    
    const accountNo = userDetails.account;
    const sender = userDetails.firstName + " " + userDetails.lastName;
    const receiver = cryptoAddressToCredit;
    const bank = cryptoTypeToCredit;
    const receiverAcc = cryptoAddressToCredit;
    const withdrawalAmountToSend = cryptoAmountToSend;
    const desc = description;
    const operation = "debit";
    setProcessing(true);

    // Simulate processing time for payment initiation (30%)
    setTimeout(() => {
      setProgress(30);
    }, 2000);

    // Simulate processing time for completing transaction (80%)
    setTimeout(() => {
      setProgress(80);
      axios
      .post(`${apiUrl}/transfer`, {
        withdrawalAmount: withdrawalAmountToSend,
        accountNo:accountNo,
        sender: sender,
        receiver: receiver,
        bank:bank,
        receiverAcc: receiverAcc,
        description: desc,
        operation: operation,
      }, { description })
      .then((response) => {
        setTransactionId(response.data.id);
        console.log("Withdrawal success");
      })
      .catch((error) => {
        console.error("Withdrawal failed:", error);
      });
      openSnackbar("Transfer Successful!!")
    }, 5000);

    // Simulate processing time for successful transaction (100%)
    setTimeout(() => {
      setTransactionSuccess(true);
      setProcessing(false);
      setProgress(100);
    }, 10000);
    setProgress(0);
  };

  const openSnackbar = (message) => {
    setSnackbarKey((prevKey) => prevKey + 1);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const action = (
    <Button
      sx={{ color: "#fff" }}
      size="small"
      onClick={() => setSnackbarOpen(false)}
    >
      <i className="bi bi-x-lg"></i>
    </Button>
  );



  if(!userDetails ){
    history.push("/login");
  }

  return (
    <div className="container">
      {/* <p>Balance: {balance}</p> */}
      <br /><br /><br /><br /><br />
      <div className="row g-3">
        <div className="col-12 col-md-6 ">
          <div className="border shadow rounded px-4 pb-5 pt-2 position-relative">
            <h5 className="pt-5 pb-2 text-black-50">Transfer to Wallet</h5>
            <div
              className="bg-secondary-subtle p-1 rounded-5 mt-3"
              style={{
                position: "absolute",
                top: "0",
                right: "20px",
                fontSize: "12px",
              }}
            >
              <small className="p-2 text-uppercas">
                {
                  cryptoTypeToCredit === "bitcoin(btc)" 
                  ? <><img className="rounded-5 me-1" src={btc} alt="" width={20}/> BITCOIN(BTC)</>
                  : cryptoTypeToCredit === "ethereum(eth)" 
                  ? <><img className="rounded-5 me-1" src={eth} alt="" width={20}/> ETHEREUM(ETH)</>
                  : cryptoTypeToCredit === "usdt(trc-20)"  
                  ? <><img className="rounded-5 me-1" src={usdt} alt="" width={20}/> USDT(TRC-20)</>
                  : cryptoTypeToCredit === "usdt(erc-20)"
                  ? <><img className="rounded-5 me-1" src={usdt} alt="" width={20}/> USDT(ERC-20)</>
                  : "No Network Selected"
                }
              </small>
            </div>

            <form onSubmit={handleWithdrawal}>
              <FormControl className="" fullWidth required>
                <InputLabel id="bitcoinAddressName">Select Network</InputLabel>
                <Select
                  size="small"
                  labelId="bitcoinAddressName"
                  value={cryptoTypeToCredit}
                  label="Select crypto"
                  onChange={(e) => setCryptoTypeToCredit(e.target.value)}
                >
                  <MenuItem value="">
                    <p className="mb-0">None</p>
                  </MenuItem>

                  <MenuItem value={"bitcoin(btc)"}>
                    <img className="rounded-5 me-1" src={btc} alt="" width={20}/> BITCOIN(BTC)
                  </MenuItem>

                  <MenuItem value={"ethereum(eth)"}>
                    <img className="rounded-5 me-1" src={eth} alt="" width={20}/> ETHEREUM(ETH)
                  </MenuItem>

                  <MenuItem value={"usdt(trc-20)"}>
                    <img className="rounded-5 me-1" src={usdt} alt="" width={20}/> USDT(TRC-20)
                  </MenuItem>

                  <MenuItem value={"usdt(erc-20)"}>
                    <img className="rounded-5 me-1" src={usdt} alt="" width={20}/> USDT(ERC-20)
                  </MenuItem>

                </Select>
              </FormControl>

              <TextField
                fullWidth
                className="d-block mt-3"
                type="text"
                label={!cryptoTypeToCredit ? "No Network Selected" : `Enter ${cryptoTypeToCredit} address`}
                variant="outlined"
                size="small"
                value={cryptoAddressToCredit}
                onChange={(e) => setCryptoAddressToCredit(e.target.value)}
                required
                disabled={processing}
              />
              <FormHelperText>
                {!cryptoTypeToCredit ? "No Network Selected"
                  : `PS enter the correct ${cryptoTypeToCredit} address`}
              </FormHelperText>

              <TextField
                fullWidth
                className="mt-3 d-block"
                type="text"
                label="Withdrawal Amount"
                variant="outlined"
                size="small"
                value={cryptoAmountToSend}
                onChange={(e) => setCryptoAmountToSend(e.target.value)}
                required
                disabled={processing}
              />

              <TextField
                fullWidth
                className="mt-3 d-block"
                type="text"
                id="outlined-basic"
                label="Description"
                variant="outlined"
                size="small"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                disabled={processing}
              />

              <Button
                type="submit"
                variant="contained"
                className="mt-3 d-block"
                size="small"
                onClick={handleWithdrawal}
                disabled={processing}
              >
                {processing ? "Processing..." : "Initiate Withdrawal"}
              </Button>
            </form>

            {processing && (
              <Box sx={{ width: "100%", marginTop: 2 }}>
                <LinearProgressWithLabel value={progress} />
              </Box>
            )}
            {transactionSuccess && progress === 100 && (
              <div className="py-4">
                <Button variant="outlined" onClick={() =>
                          history.push(`/print-receipt/${transactionId}`)
                        }>show receipt</Button>
                <p className="py-4">Transfer Successful!</p>
              </div>
            )}
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="border shadow rounded py-5 px-4">
            <p>
              Difficulties transfering through any crypto currency? <br />{" "}
              contact support with your conplains
            </p>
            <Button variant="outlined">support</Button>
          </div>
        </div>
      </div><br /><br /><br /><br />

       {/* Alert box */}
       <Snackbar
          key={snackbarKey}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          action={action}
        />

    </div>
  );
};

export default WithdrawCrypto;
