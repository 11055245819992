import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { useUser } from "../components/UserContext";
// import Button from "@mui/material/Button";
import { Modal, Button } from 'react-bootstrap';
import { Avatar } from "@mui/material";
import Arrow from '@mui/icons-material/CompareArrows';
import Card from '@mui/icons-material/Payment'
import Track from '@mui/icons-material/TrendingUp'
import Setting from '@mui/icons-material/Settings'



export default function SelfService() {
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const { user: userDetails } = useUser();
  const location = useLocation();
  const client = location.state && location.state.client;

  const [balance, setBalance] = useState(0);
  const profilePicture = (client && client.imageFileName) || (userDetails && userDetails.imageFileName);

  const history = useHistory();

  const amount = balance;
  const [rates, setRates] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [isUsingInput, setIsUsingInput] = useState(false);
  const [inputedAmount, setInputedAmount] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const baseCurrency = "USD";

  useEffect(() => {
    const storedUserInfo = localStorage.getItem("userInfo");
    if (storedUserInfo) {
      const { account } = JSON.parse(storedUserInfo);
      // Ensure that account is available before making the request
      if (account) {
        axios
          .get(`${apiUrl}/clients/${account}`)
          .then((response) => {
            setBalance(response.data);
            
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  }, [apiUrl]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `https://openexchangerates.org/api/latest.json?app_id=d05455be758a4fd8a8478889c40a55ac&base=${baseCurrency}`
      )
      .then((response) => {
        setRates(response.data.rates);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  // Filter out only AUD, NGN, GBP, and BTC rates
  const filteredRates = Object.keys(rates)
    .filter(
      (currency) =>
        currency === "AUD" ||
        currency === "NGN" ||
        currency === "GBP" ||
        currency === "BTC"
    )
    .reduce((obj, key) => {
      obj[key] = rates[key];
      return obj;
    }, {});


    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
  
    const handleCloseModal1 = () => setShowModal1(false);
    const handleCloseModal2 = () => setShowModal2(false);
    const handleCloseModal3 = () => setShowModal3(false);
  


  return (
    <div>
      <div className="pb-5 pt-5 selfservice">
        <br /><br /><br />

        <div className="mx-2">
          <div className="">
          {balance && balance.map((user) => (
              <div key={user.id}>
                <div className="my-2 balance">
                  <div className="ms-5 amount">
                    {user.balance === 0 ? (
                      "$00.00"
                    ) : (
                      <div className="d-flex">

                        <div>
                          {profilePicture ? (
                            <Avatar
                            sx={{ border: "3px solid #bbbbbb", cursor: "pointer" }}
                            src={`${apiUrl}/images/${profilePicture}`}
                            onClick={() => history.push("/settings?setModal=true")}
                          ></Avatar>
                          ) : (
                            <Avatar/>
                          )}
                        </div>

                        {/* <div className='ms-auto'> */}
                        <h5 className='my-auto ms-5'>Balance</h5>

                        <div className='d-flex my-auto ms-2'>
                          <div>$</div>
                          <div>
                            {(client && client.balance !== undefined
                              ? client.balance
                              : user.balance
                            ).toLocaleString("en-US")}
                          </div>
                          <div>.00</div>
                        </div>
                        {/* </div> */}

                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>


        <h3 className="py-4 fw-bolder text-center">Self Service</h3> 
        <div className="container text-center m-auto row g-2">
          <div
            className="col-6"
            onClick={() => setShowModal1(true)} >
            <div className="border shadow-sm rounded p-3 selfservice-card">
              <Arrow style={{ fontSize: "50px" }}/>
              <p>Balance Conversion</p>
            </div>
          </div>

          <div className="col-6"
        //   onClick={() => setShowModal3(true)} 
          onClick={() => history.push("/card")} 
        >
            <div className="border shadow-sm rounded p-3 selfservice-card">
            <Card style={{ fontSize: "50px" }}/>
              <p>Card Services</p>
            </div>
          </div>

          <div className="col-6" onClick={setShowModal3}>
            <div className="border shadow-sm rounded p-3 selfservice-card">
            <Track style={{ fontSize: "50px" }}/>
              <p>Track Expenses</p>
            </div>
          </div>

          <div className="col-6">
            <div className="border shadow-sm rounded p-3 selfservice-card">
            <Setting style={{ fontSize: "50px" }}/>
              <p>More</p>
            </div>
          </div>
        </div> 
      </div>


      {/* FIRST MODAL */}
      <Modal
        show={showModal1}
        onHide={handleCloseModal1}
        aria-labelledby="exampleModalToggleLabel"
        backdrop="static"
      >
        <Modal.Header closeButton className="bg-light">
          <Modal.Title id="exampleModalToggleLabel">Your balance in major currencies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h5>Your balance in major currencies</h5>

            {isLoading ? (
            <p>Loading...</p>
            ) : (
            <div>
                {Object.keys(filteredRates).map((currency) => (
                <div key={currency}>
                    <div className="d-flex">
                    <div className="">
                        <h4 className="mb-0">USD</h4>
                        <p className="mt-0">{balance.toLocaleString("en")}</p>
                    </div>
                    <i className="bi bi-arrow-left-right mt-3 mx-4"></i>
                    <div className="">
                        <h4 className="mb-0">{currency}</h4>
                        <p className="mt-0 text-danger">
                            {(filteredRates[currency] * amount)
                            .toLocaleString("en-US")}
                        </p>
                    </div>
                    </div>
                </div>
                ))}
            </div>
            )}

        <div className="my-2">
            <Button variant="secondary shadow"
            onClick={() => {
                setShowModal2(true);
                setShowModal1(false);
                setIsUsingInput(true)
                }}>
            Convert to other currencies
            </Button>
        </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleCloseModal1()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* SECOND MODAL */}
      <Modal
        show={showModal2}
        onHide={handleCloseModal2}
        aria-labelledby="exampleModalToggleLabel2"
        backdrop="static"
      >
        <Modal.Header closeButton className="bg-light">
          <Modal.Title id="exampleModalToggleLabel2">Convert to other currencies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h5 className="modal-title" id="usdModalLabel">
            Convert USD to different currencies</h5>
            <div className="">
                <div className="mb-3">
                  <input
                    className="form-control"
                    type="text"
                    value={inputedAmount}
                    onChange={(e) => setInputedAmount(e.target.value)}
                    placeholder="Enter amount in USD"
                  />
                  <small>Enter amount in USD</small>
                </div>

                <div className="">
                  <select
                    className="form-control"
                    value={selectedCurrency}
                    onChange={(e) => setSelectedCurrency(e.target.value)}
                  >
                    {Object.keys(rates).map((currency) => (
                      <option key={currency} value={currency}>
                        {currency}
                      </option>
                    ))}
                  </select>
                  <small>Select currency to convert</small>
                </div>

                {Object.keys(rates).map((currency) => (
                  <div
                    className="py-5 position-relative"
                    key={currency}
                    style={{
                      display: currency === selectedCurrency ? "block" : "none",
                    }}
                  >
                    {inputedAmount !== "" ? (
                        <div className="py-4 bg-dar position-absolute top-0">
                        <h4 className="mb-0">
                          {parseFloat(inputedAmount).toFixed(2)} USD
                        </h4>
                        <i className="bi bi-arrow-down-up position-absolute end-50 "></i>
                        <h4 className="mt-4">
  {(rates[currency] * (isUsingInput ? parseFloat(inputedAmount) : amount)).toLocaleString("en-US", { minimumFractionDigits: 3, maximumFractionDigits: 3 })} {currency}
</h4>

                      </div>
                    ) : (
                        <p className="m-0">Enter Amount in USD  and select currencies to convert</p>
                    )}
                  </div>
                ))}
              </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => {
            setShowModal2(false);
            setShowModal1(true);
          }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* TRACK EXPENSES */}
      <Modal
        show={showModal3}
        onHide={handleCloseModal3}
        aria-labelledby="exampleModalToggleLabel2"
        backdrop="static"
      >
        <Modal.Header closeButton className="bg-light">
          <Modal.Title id="exampleModalToggleLabel2">
            Track Expenses
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => {
            setShowModal2(false);
            setShowModal1(true);
          }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}
