import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./css/App.css";
import { useUser } from "./components/UserContext";
import Navbar from "../src/components/Navbar";
import Footer from "../src/components/Footer";
import Form from "../src/components/Account/Form";
import Login from "../src/components/Account/Login";
import Settings from "../src/components/Settings";
import Dashboard from "../src/components/Dashboard";
import SelfService from "./components/SelfService";
import Admin from "../src/components/admin/Admin";
import History from "../src/components/History";
import printReceipt from "../src/components/Print-receipt";
import contactForm from "./components/ContactForm";
import NotFound from "../src/components/NotFound";
import UserProfile from "../src/components/UserProfile";
import Notifications from "./components/Notifications";
import DepositeCrypto from "./components/Deposite-crypto";
import card from "../src/components/Card";
import WithdrawCrypto from "../src/components/WithdrawCrypto";
import ClientChat from "../src/components/ClientChat";
import AdminChat from "../src/components/AdminChat";
import PwaButton from "../src/components/PwaButton";
import Fab from "@mui/material/Fab";
import Modal from "react-bootstrap/Modal";
import Send from "@mui/icons-material/Send";
import Chat from "@mui/icons-material/Chat";
import { IconButton } from "@mui/material";
import CloseButton from "@mui/icons-material/Close";
import notificationSound from "./images/mixkit-bell-notification-933.wav";
import Domestic from "./components/Actions/Wire";

function App() {
  const {
    user: userDetails,
    handleSendMessage,
    handleSendReply,
    messageModalOpen,
    setMessageModalOpen,
    fetchNewMessages,
  } = useUser();
  const [replyMessage, setReplyMessage] = useState("");
  const [sendMessage, setSendMessage] = useState("");
  const [newMessages, setNewMessages] = useState([]); // Store incoming messages
  const [prevMessageCount, setPrevMessageCount] = useState(0);
  const clientId = localStorage.getItem("clientId") || `client_${Date.now()}`;

  // Preload the notification sound
  const audio = new Audio(notificationSound);

  const handleShow = () => {
    setMessageModalOpen(true);
  };

  const handleClose = () => {
    setMessageModalOpen(false);
  };

  const sendNewMessage = () => {
    handleSendMessage(sendMessage);
    setSendMessage("");
  };

  const sendReply = () => {
    handleSendReply(replyMessage);
    setReplyMessage("");
  };

  // Play notification sound
  const playNotificationSound = useCallback(() => {
    console.log("Playing sound..."); // Check if the sound is being triggered
    audio.play().catch((error) => {
      console.error("Error playing sound:", error);
    });
  }, [audio]);

  // Function to check for new messages and play the notification sound if a new message arrives
  const checkForMessages = useCallback(async () => {
    if (clientId) {
      const messages = await fetchNewMessages(clientId);
      setNewMessages(messages);

      // Compare the length of new messages with the previous count
      if (messages.length > prevMessageCount) {
        console.log("New message received:", messages); // Check if new messages are detected
        playNotificationSound();
      }

      // Update the previous message count
      setPrevMessageCount(messages.length);
    }
  }, [clientId, fetchNewMessages, playNotificationSound, prevMessageCount]);

  // Start checking for messages immediately after the app mounts
  useEffect(() => {
    const intervalId = setInterval(checkForMessages, 5000);

    return () => clearInterval(intervalId);
  }, [checkForMessages]);

  return (
    <Router>
      <Navbar />
      <PwaButton />

      <div>
        <Fab
          color="primary"
          aria-label="dashboard"
          onClick={handleShow}
          style={{
            position: "fixed",
            bottom: "85px",
            right: "15px",
          }}
        >
          <Chat />
        </Fab>

        <div className="modal">
          <Modal
            show={messageModalOpen}
            onHide={handleClose}
            size="lg"
            dialogClassName="modal-wrapper"
            className="chatModal"
            backdrop={false}
            fullscreen
          >
            <Modal.Header className="header shadow-sm text-white">
              <Modal.Title>
                <div>
                  <small>Townlink </small>
                  <br />
                  <small style={{ fontSize: "12px" }}>
                    Typically reply in minutes
                  </small>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div>
                {userDetails?.role === "admin" ? <AdminChat /> : <ClientChat />}
              </div>

              <div className="closeBtn">
                <IconButton className="button" onClick={handleClose}>
                  <CloseButton />
                </IconButton>
              </div>
            </Modal.Body>

            <Modal.Footer className="border-0 w-100 sendButtonAndInput">
              <div className="input-group p-0">
                <textarea
                  className="form-control rounded sendButton"
                  placeholder={
                    userDetails?.role === "admin"
                      ? "Type reply"
                      : "Type your message"
                  }
                  value={
                    userDetails?.role === "admin" ? replyMessage : sendMessage
                  }
                  onChange={
                    userDetails?.role === "admin"
                      ? (e) => setReplyMessage(e.target.value)
                      : (e) => setSendMessage(e.target.value)
                  }
                  style={{ resize: "none", height: "50px" }}
                />
                <IconButton
                  className="sendBtn ms-2"
                  onClick={
                    userDetails?.role === "admin" ? sendReply : sendNewMessage
                  }
                >
                  <Send />
                </IconButton>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      <div className="page">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/navbar" component={Navbar} />
          <Route exact path="/form" component={Form} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route path="/dashboard/:clientId" component={Dashboard} />
          <Route exact path="/self-service" component={SelfService} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/history" component={History} />
          <Route exact path="/history/:clientId" component={History} />
          <Route exact path="/print-receipt" component={printReceipt} />
          <Route exact path="/contactForm" component={contactForm} />
          <Route
            path="/print-receipt/:transactionId"
            component={printReceipt}
          />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/settings/:clientId" component={Settings} />
          <Route exact path="/profile" component={UserProfile} />
          <Route path="/profile/:clientId" component={UserProfile} />
          <Route exact path="/notifications" component={Notifications} />
          <Route exact path="/withdrawCrypto" component={WithdrawCrypto} />
          <Route exact path="/deposite_crypto" component={DepositeCrypto} />
          <Route exact path="/card" component={card} />
          <Route path="*" component={NotFound} />

          <Route exact path="/domestic" component={Domestic} />
        </Switch>
      </div>

      <Footer />
    </Router>
  );
}

export default App;
