import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FrontIcon from "@mui/icons-material/ArrowCircleRight";
import LeftIcon from "@mui/icons-material/ArrowCircleLeft";
import Home from "@mui/icons-material/Home";


export default function App() {
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    gender: "",
    country: "",
    state: "",
    city: "",
    street: "",
    zipCode: "",
    password: "",
    confirmPassword: "",
  });

  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    console.log(formData);
  };

  const handleRegistration = async () => {
    setLoading(true);
    try {
      // // Check for empty fields
      for (const field of Object.values(formData)) {
        if (!field) {
          openSnackbar("All fields are required", "error");
          setLoading(false);
          return; // Stop the registration process if any field is empty
        }
      }

      // Make an HTTP POST request to the backend
      const response = await axios.post(`${apiUrl}/register`, formData);

      // Check the response and handle accordingly
      if (response.status === 200) {
        console.log("Registration successful:", response.data.message);
        setTimeout(() => {
          openSnackbar(response.data.message, "success");
          setLoading(false);
        }, 3000);

        // Clear the form
        setFormData({
          firstName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          gender: "",
          country: "",
          state: "",
          city: "",
          street: "",
          zipCode: "",
          accountType: "",
          bankAddress: "",
          password: "",
          confirmPassword: "",
        });
      } else {
        console.error("Registration failed:", response.data.error);
        openSnackbar(`Registration failed: ${response.data.error}`, "error");
        setLoading(false);
        // Handle the error, show a message, or redirect the user
      }
    } catch (error) {
      console.error(error.message);
      setLoading(false);

      if (error.response && error.response.status === 409) {
        openSnackbar("Email already exists", "error");
        setLoading(false);
      } else {
        openSnackbar("Registration failed: Network error", "error");
        setLoading(false);
      }

      // Handle network error or other issues
    }
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const calculateProgress = () => {
    return (currentStep / 4) * 100;
  };

  const openSnackbar = (message) => {
    setSnackbarKey((prevKey) => prevKey + 1);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const action = (
    <Button
      sx={{ color: "#fff" }}
      size="small"
      onClick={() => setSnackbarOpen(false)}
    >
      <i className="bi bi-x-lg"></i>
    </Button>
  );



  return (
    <div className="">
      <br /><br /><br /><br /><br />
      <div className="register-form">

      <div className="ms-3 d-flex">
          <IconButton onClick={() => history.goForward()}>
            <LeftIcon sx={{ width: 35, height: 35 }} />
          </IconButton>

          <IconButton onClick={() => history.goBack()}>
            <FrontIcon sx={{ width: 35, height: 35 }} />
          </IconButton>

          <IconButton onClick={() => history.push("admin")}>
            <Home sx={{ width: 35, height: 35 }} />
          </IconButton>
        </div>


        {currentStep === 1 && (
          <div className="steps">
            <div className="p-4 progress">
              <progress value={calculateProgress()} max="100"></progress>
            </div>
            <div className="p-4">
              <h2>Personal Information</h2>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name"
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="phone"
                  className="form-control"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Phone Number"
                />
              </div>

              <div className="input-group mb-3">
                <select
                  className="form-select"
                  name="gender"
                  onChange={handleChange}
                  id=""
                >
                  <option selected disabled>
                    Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>


              <div className="pb-5 mb-5">
                <button
                  className="btn btn-danger"
                  onClick={() => history.push("/admin")}
                >
                  Cancel
                </button>
                <button className="btn btn-warning ms-3" onClick={handleNext}>
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
        {currentStep === 2 && (
          <div className="steps">
            <div className="p-4 progress">
              <progress value={calculateProgress()} max="100"></progress>
            </div>
            <div className="p-4">
              <p>Two more step, you are done</p>
              <h2>Residential Address</h2>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  placeholder="Country"
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  placeholder="State"
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="City"
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="street"
                  value={formData.street}
                  onChange={handleChange}
                  placeholder="Street"
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleChange}
                  placeholder="Zip Code"
                />
              </div>
              <div className="pb-5 mb-5">
                <button
                  className="btn btn-danger me-3"
                  onClick={() => history.push("/admin")}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-secondary me-3"
                  onClick={handlePrevious}
                >
                  Previous
                </button>
                <button className="btn btn-warning" onClick={handleNext}>
                  Next
                </button>
              </div>
            </div>
          </div>
        )}

        {currentStep === 3 && (
          <div className="steps">
            <div className="p-4 progress">
              <progress value={calculateProgress()} max="100"></progress>
            </div>
            <div className="p-4">
              <p>One more step, you are done</p>
              <h2>Acount Information</h2>
              <div className="input-group mb-3 d-block">
                <input
                  type="text"
                  className="form-control w-100"
                  name="firstName"
                  value={formData.firstName}
                  placeholder="Enter First Name"
                  disabled
                />
                <small style={{ fontSize: "11px" }}>
                  Want to change your first name? go to the first step
                </small>
              </div>

              <div className="input-group mb-3 d-block">
                <input
                  type="text"
                  className="form-control w-100"
                  name="firstName"
                  value={formData.lastName}
                  placeholder="Enter Last Name"
                  disabled
                />
                <small style={{ fontSize: "11px" }}>
                  Want to change your last name? go to the first step
                </small>
              </div>

              <div className="input-group mb-3 d-block">
                <input
                  type="text"
                  className="form-control w-100"
                  name="firstName"
                  value={formData.email}
                  placeholder="Enter account email"
                  disabled
                />
                <small style={{ fontSize: "11px" }}>
                  Want to change your account email address? go to the first
                  step
                </small>
              </div>

              <div className="input-group mb-3 d-block">
                <input
                  type="number"
                  className="form-control w-100"
                  name="accountNumber"
                  placeholder="Enter account number"
                  disabled
                />
                <small style={{ fontSize: "11px" }}>
                  Account number will be generared by our system
                </small>
              </div>

              <div className="input-group mb-3">
                <select
                  className="form-select"
                  name="accountType"
                  onChange={handleChange}
                  id=""
                >
                  <option selected disabled>
                    Select account type
                  </option>
                  <option value="checking">Checking</option>
                  <option value="savings">Savings</option>
                  <option value="non-residential">Non-Residential</option>
                </select>
              </div>
              {formData.accountType}

              <div className="input-group mb-3">
                <select
                  className="form-select"
                  name="bankAddress"
                  onChange={handleChange}
                  id=""
                >
                  <option selected disabled>
                    Select Prefered bank branch
                  </option>
                  <option value="388 Greenwich St, New York, NY10013">
                    388 Greenwich St, New York, NY10013
                  </option>
                  <option value="6716 Grade Lane Building 9, Suit 910 Louisville, KY4013">
                    6716 Grade Lane Building 9, Suit 910 Louisville, KY4013{" "}
                  </option>
                  <option value="1001 West 49th St Hialeah ,floor 33012">
                    1001 West 49th St Hialeah ,floor 33012
                  </option>
                </select>
              </div>

              <div className="pb-5 mb-5">
                <button
                  className="btn btn-danger me-3"
                  onClick={() => history.push("/admin")}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-secondary me-3"
                  onClick={handlePrevious}
                >
                  Previous
                </button>
                <button className="btn btn-warning" onClick={handleNext}>
                  Next
                </button>
              </div>
            </div>
          </div>
        )}

        {currentStep === 4 && (
          <div className="steps">
            <div className="p-4 progress">
              <progress value={calculateProgress()} max="100"></progress>
            </div>
            <div className="p-4">
              <h2>Create Your Login</h2>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                />
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirm Password"
                />
              </div>
              <div className="mb-5">
                <button
                  className="btn btn-danger me-3"
                  onClick={() => history.push("/admin")}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-secondary me-3"
                  onClick={handlePrevious}
                >
                  Previous
                </button>
              </div>
              <div className="text-center mb-5">
                <button
                  className="btn btn-success"
                  onClick={handleRegistration}
                >
                  {loading ? (
                    <div>
                      Please wait...
                      <i className="spinner spinner-border spinner-border-sm ms-4"></i>
                    </div>
                  ) : (
                    "Finish Registration"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Alert box */}
      <Snackbar
        key={snackbarKey}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={action}
      />
    </div>
  );
}
