import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useUser } from "../components/UserContext";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

import moment from "moment";
import axios from "axios";

const UserProfile = () => {
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const { user: userDetails } = useUser();
  const [loading, setLoading] = useState(true); 

  const location = useLocation();
  const [client, setClient] = useState(null)
  
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const profilePicture = userDetails && userDetails.imageFileName;

  const history = useHistory();

  useEffect(() => {
    if (id) {
      axios
        .get(`${apiUrl}/clients/${id}`)
        .then((response) => {
          // Assuming the response is an array, extract the first object
          const clientData = response.data?.[0] || null; // Safely access the first element
          setClient(clientData);
        })
        .catch((error) => {
          console.error("Error fetching client data:", error);
        })
        .finally(() => setLoading(false));
    } else {
      setClient(userDetails);
      setLoading(false);
    }
  }, [id, apiUrl, userDetails]);

  const handleClientClick = (client) => {
    if (client && client.id) {
      history.push({
        pathname: `/settings/${client.id}`,
        state: { client: client }
      });
    } else {
      history.push("/settings");
    }
  };
  



  return (
    <div className={`container ${userDetails?.role === 'admin' && 'mt-3'}`}>
      <br /><br /><br /> <br />
      
      <div className="py-3 pb-5 row g-2">
        <div className="col-12 col-md-6 text-capitalize text-center">
          <div
            className="p-4 border rounded"
            style={{ height: "400px", boxShadow: "0 0 28px #0002" }}
          >
            <div className="d-flex justify-content-between ">
              <h5 style={{ color: "#4389c2", fontWeight: "bold" }}>
                {(
                  (client?.firstName.charAt(0)
                  .toUpperCase() ) ||
                  (userDetails && userDetails.firstName.charAt(0)
                  .toUpperCase() )
                )
                  +
                  (
                    (client?.firstName.slice(1)) ||
                    (userDetails && userDetails.firstName.slice(1))
                  )}{" "}
                Profile
              </h5>
              <div className="d-flex">
              <div
                className=" rounded-5 text-center"
                style={{
                  width: "30px",
                  height: "30px",
                  lineHeight: "2",
                  boxShadow: "0 0 20px #4389c2",
                  background: "#4289c2",
                  cursor: "pointer",
                }}
                onClick={() => {
                    handleClientClick(client) ;
                }}
                
              >
                <i className="bi bi-pencil-square text-white"></i>
              </div>
                {userDetails?.role === 'admin' && <div className='ms-3'>
                  <Button variant="contained" size="small" onClick={() => 
                  history.push({
                    pathname: `/dashboard/?id=${client.id }`,
                    state: { client: client }
                  })}>dashbord</Button>
                </div>}
              </div>
             

            </div>
            <div className="" >
              {/* <div className="profileImage mt-5">
              {(client && client) || (userDetails && userDetails) ? (
                <Avatar
                  sx={{ border: "1px solid #4289c2", scale:"1.5", cursor: "pointer" }}
                  src={`${apiUrl}/images/${profilePicture}`}
                ></Avatar>
              ) : (
                <Avatar />
              )}
              </div> */}

              <div className="profileImage mt-5">

                  {client ? (
                      <div>
                      {client ? (
                        <Avatar
                        sx={{ border: "1px solid #4289c2", scale:"1.5", cursor: "pointer" }}
                        src={`${apiUrl}/images/${(client.imageFileName)}`}
                      ></Avatar>
                      ) : (
                        <Avatar/>
                      ) }
                    </div>
                  ) : (
                    <div>
                      {profilePicture ? (
                      <Avatar
                      sx={{ border: "1px solid #4289c2", scale:"1.5", cursor: "pointer" }}
                      src={`${apiUrl}/images/${profilePicture}`}
                    ></Avatar>
                    ) : (
                      <Avatar/>
                    ) }
                    </div>
                  )}
              
                    
              </div>

              <p className="mt-3 text-capitalize fw-bold text-muted">{`${
                (client && client.firstName) ||
                (userDetails && userDetails.firstName)
              } ${
                (client && client.lastName) ||
                (userDetails && userDetails.lastName)
              }`}</p>
            </div>
            <p className="m-0 text-lowercase">
              {(client && client.email) || (userDetails && userDetails.email)}
            </p>
            <p className="m-0">
              {(client && client.country) ||
                (userDetails && userDetails.country)}
              {(client && client.state) || (userDetails && userDetails.state)
                ? `(${
                    (client && client.state) ||
                    (userDetails && userDetails.state)
                  })`
                : "State Not Available"}
            </p>
            
          </div>
        </div>

        {/* Other profile sections */}
        <div className="col-12 col-md-6 text-capitalize">
          
          <div
            className="border rounded p-4 overflow-auto"
            style={{ height: "400px", boxShadow: "0 0 28px #0002" }}
          >
            <div className="mb-3">
              <h5 style={{ color: "#4389c2", fontWeight: "bold" }}>
                Bank Details
              </h5>
            </div>
            <div>
              <p className="m-0 text-muted">Account Name</p>
              <h6 className="text-capitalize">
                {(client && client.firstName) ||
                  (userDetails && userDetails.firstName)}{" "}
                {(client && client.lastName) ||
                  (userDetails && userDetails.lastName)}
              </h6>
            </div>
            
            <div className="d-flex">
            <div>
              <p className="m-0 text-muted">Bank</p>
              <h6 className="text-capitalize">
                Townlink bank
              </h6>
            </div>

            <div className="ms-5">
              <p className="m-0 text-muted">Account No.</p>
              <h6>
                {(client && client.account) ||
                  (userDetails && userDetails.account)}
              </h6>
            </div>
            </div>

            <div className="d-flex">
              <div>
              <p className="m-0 text-muted">Account Type</p>
              <h6>
                {(client && client.accountType) ||
                  (userDetails && userDetails.accountType)}
              </h6>
              </div>

              <div className="ms-5"> 
              <p className="m-0 text-muted">Swift Code</p>
              <h6>
                {(client && client.swiftCode) ||
                  (userDetails && userDetails.swiftCode)}
              </h6>
              </div>

            </div>
              <div className=""> 
              <p className="m-0 text-muted">Routing for wire transfer</p>
              <h6>
                {(client && client.routing) ||
                  (userDetails && userDetails.routing)}
              </h6>
              </div>

              <div className=""> 
              <p className="m-0 text-muted">Bank branch Address</p>
              <h6>
                {(client && client.bankAddress) ||
                  (userDetails && userDetails.bankAddress)}
              </h6>
              </div>
            <hr />

            <h5 style={{ color: "#4389c2", fontWeight: "bold" }}>
                Other Bank
              </h5>

            <div>
              <p className="m-0 text-muted">Bank</p>
              <h6 className="text-capitalize">
                {(client && client.bank) || (userDetails && userDetails.bank)}
              </h6>
            </div>
            <div>
              <p className="m-0 text-muted">Account No.</p>
              <h6>
                {(client && client.account) ||
                  (userDetails && userDetails.account)}
              </h6>
            </div>
            {/* Render other bank details */}
          </div>
        </div>

        <div className="col-12 col-md-6 text-capitalize">
          {/* Account Manager Details */}
          <div
            className="p-4 border rounded"
            style={{ height: "400px", boxShadow: "0 0 28px #0002" }}
          >
            <div className="mb-3">
              <h5 style={{ color: "#4389c2", fontWeight: "bold" }}>
                Account Manager Details
              </h5>
            </div>
            <div>
              <p className="m-0 text-muted">Name</p>
              <h6 className="text-capitalize">Not Available</h6>
            </div>
            <div>
              <p className="m-0 text-muted">Email</p>
              <h6>Not Available</h6>
            </div>
            <div>
              <p className="m-0 text-muted">Phone</p>
              <h6 className="text-capitalize">Not Available</h6>
            </div>
            <div>
              <p className="m-0 text-muted">Assigned On</p>
              <h6>
                {moment(
                  (client && client.date) || (userDetails && userDetails.date)
                ).format("MMMM D, YYYY")}
              </h6>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 text-capitalize">
          {/* Contact Details */}
          <div
            className="p-4 border rounded"
            style={{ height: "400px", boxShadow: "0 0 28px #0002" }}
          >
            <div className="mb-3">
              <h5 style={{ color: "#4389c2", fontWeight: "bold" }}>
                Contact Details
              </h5>
            </div>
            <p>
              {(client && client.email) || (userDetails && userDetails.email)}
            </p>
            <p>
              {(client && client.phoneNumber) ||
                (userDetails && userDetails.phoneNumber)}
            </p>
            <p>
              {(client && client.country) ||
                (userDetails && userDetails.country)}
              {(client && client.state) || (userDetails && userDetails.state)
                ? `(${
                    (client && client.state) ||
                    (userDetails && userDetails.state)
                  })`
                : "State Not Available"}
            </p>
            <p>
              Zipcode:{" "}
              {(client && client.zipcode) ||
                (userDetails && userDetails.zipcode)}
            </p>
            {/* Render other contact details */}
          </div>
        </div>
      </div>

    </div>
  );
};

export default UserProfile;
