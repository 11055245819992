import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import BellIcon from "@mui/icons-material/Notifications";
import Settings from "@mui/icons-material/SettingsSuggest";
import Logout from "@mui/icons-material/LogoutOutlined";
import MailIcon from "@mui/icons-material/MailOutlined";
import HeadSetIcon from "@mui/icons-material/HeadsetMicOutlined";

import Accordion, { accordionClasses } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import GoogleTranslate from './GoogleTranslate';

import { Modal } from "react-bootstrap";

import { useUser } from "../components/UserContext";

export default function AccountMenu() {
  // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const location = useLocation();
  const client = location.state && location.state.client;
  const apiUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_DEV
      : process.env.REACT_APP_API_URL_PROD;

  const history = useHistory();
  const {
    user: userDetails,
    logoutUser,
    notificationOpened,
    isContactOffcanvasOpen,
    setIsContactOffcanvasOpen,
  } = useUser();
  // const id = userDetails && userDetails.id;
  const firstName = userDetails && userDetails.firstName;
  const lastName = userDetails && userDetails.lastName;
  const account = userDetails && userDetails.account;
  const role = userDetails && userDetails.role;
  const supportPin = userDetails && userDetails.supportPin;
  const profilePicture = userDetails && userDetails.imageFileName;

  const [unreadNotifications, setUnreadNotifications] = useState(0);

  const [hideSupportPin, setHideSupportPin] = useState(true);
  const [copiedPin, setCopiedPin] = useState("");

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleLogout = () => {
    logoutUser();
    history.push(`/login`);
  };

  const [activeSpan, setActiveSpan] = useState("Home");

  const handleSpanClick = (spanName) => {
    setActiveSpan(spanName);
  };




  // NOTIFICATION COUNT / DOT
  useEffect(() => {
    axios
      .get(`${apiUrl}/history/${account}`)
      .then((response) => {
        const unreadCount = response.data.filter(
          (notification) => notification.msgRead === "0"
        ).length;
        setUnreadNotifications(unreadCount);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl, account]);

  useEffect(() => {
    if (notificationOpened) {
      axios.get(`${apiUrl}/history/${account}`).then((response) => {
        const unreadCount = response.data.filter(
          (notification) => notification.msgRead === "0"
        ).length;
        setUnreadNotifications(unreadCount);
      });
    }
  });


  const copySupportPin = (pin) => {
    navigator.clipboard
    .writeText(pin)
    .then(() => {
      setCopiedPin(pin);
      setTimeout(() => setCopiedPin(null), 2000);
    })
    .catch((error) => console.error("Copy to clipboard failed: ", error));
  }

  //fetch changes in user details immediately page refreshes
  useEffect(() => {}, [userDetails]);

  if(!userDetails){
    return;
  }
  
  return (
    <div className='header-container fixed-top'>
      <div className="shadow-sm z-2 py-3 header-wrapper" >
        
      <div className="header">

        {/* notification */}
        <div>
          <IconButton
            id="demo-positioned-button"
            aria-haspopup="true"
            className="menu-link me-2 text-light"
            size="small"
            onClick={() => history.push("/notifications")}
            >
            <Badge
              badgeContent={
                unreadNotifications.length !== 0 && unreadNotifications
              }
              className="text-light"
              // color=""
              variant={unreadNotifications.length}
              >
              <BellIcon />
            </Badge>
          </IconButton>
          </div>

{/* menu */}
          <div className="">
          {profilePicture ? (
            <Avatar
              size="small"
              sx={{
                width: "30px",
                height: "30px",
                cursor: "pointer",
                border: ".13rem solid #f6f6f6",
              }}
              src={`${apiUrl}/images/${profilePicture}`}
              onClick={handleOpenMenu}
              />
              ) : userDetails && !profilePicture ? (
                <Avatar
                size="small"
                sx={{
                  width: "30px",
                  height: "30px",
                  cursor: "pointer",
                  background: "#0005",
                  color:"white",
                  border: "1px solid #fff",
                }}
                onClick={handleOpenMenu}
                >
              <h6
                style={{
                  color: "#adadad",
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "0",
                  margin: "auto",
                }}
                >
                {userDetails &&
                  firstName &&
                  lastName &&
                  `${firstName.charAt(0).toUpperCase()}${lastName
                    .charAt(0)
                    .toUpperCase()}`}
              </h6>
            </Avatar>
          ) : (
            <Avatar
              size="small"
              sx={{
                width: "30px",
                height: "30px",
                cursor: "pointer",
                // background: "#004aad",
                background: "red",
                border: "1px solid #ec25fe",
              }}
              onClick={handleOpenMenu}
              />
              )}
        </div>

{/* greeting */}
        <div className='mt-2 mx-2'>
        {userDetails ? (
         <div> 
          <p className="name">
            Hi,{" "}
            <span className='text-capitalize'>
            {lastName &&
              lastName.substring(0, 10)+
              (lastName.length > 10 ? "..." : "")}! 
            </span>
          </p> 
          </div>
        ) : (
         <p className="name px-2">Welcome back!</p>
        )}
        </div>



       

{/* translate */}
      <div className='ms-auto' style={{width:'60px'}}>
          <div className='ms-auto'>
            <GoogleTranslate />
          </div>
      </div>




      </div>

      <div className='bg-dark'> 
        <Box className="nav">
          <div className="nav-items">
            {userDetails && userDetails.role === "client" && (
              <div className="">
                <div
                  className="menu" >
                  <div className="allmenu first" id="first">
                    <div className="firstItems">
                      <span
                        className={
                          activeSpan === "Home" ? "active-span" : "link"
                        }
                        onClick={() => {
                          handleSpanClick("Home");
                          history.push("dashboard");
                        }}
                      >
                        <i className="bi bi-x-diamond-fill"></i>
                        {activeSpan !== "Home" ? "" : "Home"}
                      </span>

                      <span
                        className={
                          activeSpan === "Services" ? "active-span" : "link"
                        }
                        onClick={() => {
                          handleSpanClick("Services");
                          history.push("Self-service");
                          }
                        }
                      >
                        <i className="bi bi-tools"></i>
                        {activeSpan !== "Services" ? "" : "Services"}
                      </span>

                      <span
                        className={
                          activeSpan === "Account" ? "active-span" : "link"
                        }
                        onClick={() => {
                          handleSpanClick("Account");
                          history.push("/profile");
                        }}
                      >
                        <i className="bi bi-person-fill"></i>{" "}
                        {activeSpan !== "Account" ? "" : "Account"}
                      </span>
                    </div>
                    {/* <div className="open" onClick={hideFirst}>
                      <i className="bi bi-plus-lg"></i>
                    </div> */}
                  </div>

                  
                </div>
              </div>
            )}
          </div>
        </Box>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          // onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 0,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {userDetails ? (
            [
              <MenuItem key="profile" onClick={() => history.push("/profile")}>
                {profilePicture ? (
                  <Avatar
                    size="small"
                    sx={{
                      width: "33px",
                      height: "33px",
                      cursor: "pointer",
                      border: ".13rem solid #4289c2",
                    }}
                    src={`${apiUrl}/images/${profilePicture}`}
                  ></Avatar>
                ) : (
                  <Avatar />
                )}
                <span className="fw-bolder me-2">
                  {firstName.charAt(0).toUpperCase() + firstName.slice(1)}
                </span>{" "}
                |<span className="ms-2">{account}</span>
              </MenuItem>,
              <Divider key="divider1" />,
              <MenuItem
                key="settings"
                onClick={() => history.push("/settings")}
              >
                <Settings fontSize="small" sx={{ mr: "10px" }} />
                Settings &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </MenuItem>,
              <MenuItem
                key="contactUs"
                style={{ position: "relative" }}
                onClick={() => setIsContactOffcanvasOpen(true)}
              >
                <MailIcon fontSize="small" sx={{ mr: "10px" }} />
                Contact Us{" "}
                <i
                  style={{ position: "absolute", right: "10px" }}
                  className="bi bi-chevron-right"
                ></i>
              </MenuItem>,
              <Divider key="divider2" />,

              // anchorEl={anchorEl}
              // id="account-menu"
              // open={open}

              <div key={accordionClasses}>
                <MenuItem
                  key="SupportPin"
                  className="bg-transparent px-2"
                  sx={{
                    padding: "0",
                  }}
                >
                  <Accordion
                    expanded={expanded}
                    onChange={handleExpansion}
                    slots={{ transition: Fade }}
                    slotprops={{ transition: { timeout: 400 } }}
                    sx={{
                      // '& .MuiAccordion-region': { height: expanded ? 'auto' : "100px" },
                      // '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none', width:"100%" },
                      width: "100%",
                      maxWidth: "250px",
                      boxShadow: "none",
                      position: "relative",
                      padding: "-20px",
                      margin: "0",
                    }}
                  >
                    <AccordionSummary
                      className="bg-transparent p-0 m-0"
                      expandIcon={<ExpandMoreIcon className="" />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography className="ps-2">
                        <HeadSetIcon fontSize="small" sx={{ mr: "10px" }} />
                        Support Pin
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails
                      className="border rounded shadow-sm"
                      sx={{ cursor: "text" }}
                    >
                      <small className="text-wrap">
                        Below is your 6-digits support pin. If you contact
                        support, we may ask this to verify your account.
                      </small>
                      <div className="d-flex justify-content-between mt-3" >
                        <input
                          disabled
                          type={hideSupportPin ? 'password' : "text"}
                          value={supportPin}
                          className="rounded-1"
                          style={{
                            width:"65px",
                            border: "none",
                            background: "grey",
                            color: "#fff",
                            height:"20px",
                            padding:"0 5px",
                          }}

                         
                          
                        />
                        <button
                          className="btn btn-link p-0 text-body"
                          style={{ cursor: "copy" }}
                          onClick={() => {
                            setHideSupportPin(!hideSupportPin); 
                          }}
                        >
                          {hideSupportPin ? "Show pin" : (
                            <button className="btn btn-link p-0 text-body" onClick={() => copySupportPin(`${supportPin}`)} style={{marginTop:"-10px"}}>Copy and hide pin</button>
                          )}
                        </button>
                      </div>
                      <div style={{
                        position:'absolute',
                        transform: "translate(-50%, 50%)",
                        left:"50%",
                        top:"50%",
                        background:"",
                        textAlign:"center",
                        boxShadow: "0 4px 100px #000000",
                        padding:"0 10px",
                        zIndex:"99",

                      }} className="rounded bg-primary text-light">
                        {copiedPin && <small className="p-0 m-0">Coppied support pin</small>}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </MenuItem>
                <Divider key="divider3" />
              </div>,

              <MenuItem key="logout" onClick={handleLogout}>
                <Logout fontSize="small" sx={{ mr: "10px" }} />
                Logout
              </MenuItem>,
              <Divider key="divider4" />,
              <p
                key="footer"
                style={{
                  margin: "1px auto",
                  textAlign: "center",
                  padding: "8px",
                  color: "#0004",
                }}
              >
                Townlink bank v1.00
              </p>,
            ]
          ) : (
            <MenuItem key="login" onClick={() => history.push("/login")}>
              <Logout fontSize="small" sx={{ mr: "10px" }} />
              Login
            </MenuItem>
          )}
        </Menu>
      </div>

      {/* contact modal */}
      <div className="">
        <Modal
          show={isContactOffcanvasOpen}
          onHide={() => {
            setIsContactOffcanvasOpen(false);
          }}
          backdrop="static"
          keyboard={false}
          dialogClassName="contactUsWrapper"
        >
          <div className="">
            <Modal.Header closeButton className="bg-light border-0">
              <Modal.Title className="px-5">Contact Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="offcanvas-body contactUs px-5 mt-3">
                <div className="d-flex">
                  <i className="bi bi-envelope-at-fill"></i>
                  <a
                    className="mt-2 ms-2"
                    href="mailto:support@townlinkbank.com"
                    target="_top"
                  >
                    {" "}
                    support@townlinkbank.com
                  </a>
                </div>
                <br />
                <div className="d-flex">
                  <i className="bi bi-phone"></i>
                  <a className="mt-2 ms-2" href="tel:+1(712)600-4098">
                    {" "}
                    +17052424005
                  </a>
                </div>
                <hr />
                <a href="contactForm">Use a contact form ?</a>

                <div className="footer">
                  <small>388 Greenwich St, New York, NY10013</small>
                  <br />
                  <small>
                    6716 Grade Lane Building 9, Suit 910 Louisville, KY4013
                  </small>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    </div>
  );
}
