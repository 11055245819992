import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';

export default function PwaInstallSnackbar() {
  const [open, setOpen] = React.useState(false);
  const [deferredPrompt, setDeferredPrompt] = React.useState(null);
  const INSTALL_PROMPT_KEY = 'pwa-install-prompt-shown';

  React.useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    const userCancelledInstall = localStorage.getItem(INSTALL_PROMPT_KEY);

    if (!isStandalone && userCancelledInstall !== 'true') {
      const handleBeforeInstallPrompt = (e) => {
        e.preventDefault();
        setDeferredPrompt(e);
        setOpen(true); // Show the Snackbar when ready to prompt for install
      };

      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

      return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      };
    }
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
        localStorage.removeItem(INSTALL_PROMPT_KEY);
      } else {
        console.log('User dismissed the install prompt');
        localStorage.setItem(INSTALL_PROMPT_KEY, 'true');
      }
      setDeferredPrompt(null);
      setOpen(false); // Hide the Snackbar after the user interaction
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false); // Hide the Snackbar if the user closes it
  };

  const action = (
    <React.Fragment>
      <Button variant='contained' className='me-2 text-capitalize' onClick={handleInstallClick}>Install</Button>
      <Button variant='contained' color='error' className='me-2 text-capitalize' onClick={handleClose}>Dont install</Button>
    </React.Fragment>
  );

  return (
    <div hidden>
      {/* The Snackbar is shown only when the PWA can be installed */}
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        message="Install our app for a better experience, click 'dont install' to remain on the web version"
        action={action}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: '#ffffff',
            color: '#000000',
            width:'100%',
            maxWidth:'500px'
          }
        }}
      />
    </div>
  );
}
