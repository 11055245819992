import React, { useEffect, useState, useCallback } from "react";
import { ref, push, set, get, onValue } from "firebase/database";
import { database } from "./firebaseConfig";
import {
  TextField,
  Button,
  Avatar,
  IconButton,
} from "@mui/material";
import Send from '@mui/icons-material/Send';
import notificationSound from '../images/mixkit-bell-notification-933.wav';
import { useUser } from "../components/UserContext";

const Client = () => {
  const [messages, setMessages] = useState([]);
  const [clientInfo, setClientInfo] = useState({
    name: "",
    email: "",
  });
  const [isClientInfoSubmitted, setIsClientInfoSubmitted] = useState(false);
  const clientId = localStorage.getItem("clientId") || `client_${Date.now()}`;

  const { messageSignal, message, setMessage } = useUser();

  useEffect(() => {
    if (messageSignal) {
      sendMessage();
    }
  }, [messageSignal]);

  // Request notification permissions on load
  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);


  // Function to play the notification sound
  const playNotificationSound = useCallback(() => {
    const audio = new Audio(notificationSound);
    audio.play();
  }, []);



  // Handle client info submission (Name and Email)
  const handleClientInfoSubmit = (e) => {
    e.preventDefault();
    if (clientInfo.name && clientInfo.email) {
      setIsClientInfoSubmitted(true);
      // Store client ID in local storage
      localStorage.setItem("clientId", clientId);
    }
  };

  // Fetch client info from localStorage (if exists) and listen for messages
  useEffect(() => {
    const storedClientId = localStorage.getItem("clientId");
    if (storedClientId) {
      setIsClientInfoSubmitted(true);
    }
      const messagesRef = ref(database, `chats/${clientId}/messages`);
      const unsubscribe = onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const messagesArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setMessages(messagesArray);
  
          // Check for the latest message
          const latestMessage = messagesArray[messagesArray.length - 1];
  
          if (latestMessage && latestMessage.sender === "admin") {
              // playNotificationSound();
          }
        } else {
          setMessages([]); // Reset messages if no data is found
        }
      });
  
      return () => unsubscribe();
    }, [clientId, playNotificationSound]);
  
    
      
 

  const sendMessage = async () => {
    if (message.trim()) {
      const chatRef = ref(database, `chats/${clientId}`);

      // Fetch existing client info first
      const clientSnapshot = await get(chatRef);
      if (!clientSnapshot.exists()) {
        // Save client info (name and email) at the root level of chats/{clientId}
        await set(chatRef, {
          name: clientInfo.name,
          email: clientInfo.email,
        });
      }

      const messagesRef = ref(database, `chats/${clientId}/messages`);
      const newMessageRef = push(messagesRef);

      await set(newMessageRef, {
        text: message,
        sender: "client",
        name: clientInfo.name,
        email: clientInfo.email,
        timestamp: Date.now(),
      });

      setMessage("");
    }
  };

  return (
    <div className="chat">
      {!isClientInfoSubmitted ? (
        <form onSubmit={handleClientInfoSubmit} style={{ padding: "20px" }}>
          <div>
            <TextField
              label="Name"
              value={clientInfo.name}
              onChange={(e) =>
                setClientInfo({ ...clientInfo, name: e.target.value })
              }
              fullWidth
              required
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <TextField
              label="Email"
              type="email"
              value={clientInfo.email}
              onChange={(e) =>
                setClientInfo({ ...clientInfo, email: e.target.value })
              }
              fullWidth
              required
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Button variant="contained" color="primary" type="submit">
              Start Chat
            </Button>
          </div>
        </form>
      ) : (
        <div className="chatBody">
          <div className=''>
            {messages.map((msg) => (
              <div key={msg.id}>
                <div className={msg.sender === 'admin' ? 'adminMsgInClient' : "clientMsgInClient"}>
                  <div className='chatItem'>
                      {msg.text}
                      <div className={msg.sender === 'admin' && 'adminImg'}>
                        {msg.sender === 'admin' && <Avatar sx={{ width: '25px', height: '25px', borderRadius: '10px 10px 0 10px' }}></Avatar>}
                      </div>
                    </div>
                </div>
              </div>
            ))}
          </div>

          {messages.length === 0 && (
            <div className="d-flex">
              <div className="mt-auto mx-2">
                <Avatar style={{ width: '30px', height: '30px', borderRadius: '20px 20px 0 20px' }}>A</Avatar>
              </div>
              <div>
                <div className="p-4 bg-body-secondary" style={{ borderRadius: '20px 20px 20px 0' }}>
                  <p>Send us a message, we will reply as quickly as possible. Waiting time is usually less than 10mins but if you can't wait, kindly use our contact form <a href="/contactForm">here</a>.</p>
                </div>
                <small className='bot'>Bot</small>
              </div>
            </div>
          )}

        </div>
      )}
    </div>
  );
};

export default Client;
